@import 'tailwind-variables';
@import 'variables';

.ant-form-item {
	.ant-input-number.extd-number-input {
		border-radius: 4px; }
	&.ant-form-item-has-error {
		.ant-input-number.extd-number-input {
			border: 1px solid $borderColor-error-medium;
			color: $textColor-error-medium;
			&::placeholder {
				color: $textColor-error-medium; }
			&:hover, &:focus {
				border: 1px solid $borderColor-error-medium;
				box-shadow: none; } }
		.ant-form-item-explain-error.extd-number-input {
			color: $textColor-error-medium; } } }

.ant-form-item:not(.ant-form-item-has-error) {
	.ant-input-number.extd-number-input {
		&:hover {
			border: 1px solid $borderColor-blue-600; } }
	.ant-input-number-focused.extd-number-input {
		border: 1px solid $borderColor-blue-600;
		box-shadow: $field-shadow-focus-default; } }
