@import 'tailwind-variables';
@import 'variables';
@import 'form';
@import 'button';
@import 'input';
@import 'input-password';
@import 'input-number';
@import 'typography';
@import 'layout';
@import 'menu';
@import 'modal';
@import 'select';
@import 'table';
@import 'tab';
@import 'tree';
@import 'tag';
@import 'upload';
@import 'radio-group';
@import 'date-picker';
@import 'switch';
@import 'download-window';
@import 'dragger-wrapper';

@font-face {
	font-family: 'Inter';
	font-weight: 100;
	src: local('Inter'), url('../assets/fonts/Inter-Thin.ttf') format('truetype'); }

@font-face {
	font-family: 'Inter';
	font-weight: 200;
	src: local('Inter'), url('../assets/fonts/Inter-ExtraLight.ttf') format('truetype'); }

@font-face {
	font-family: 'Inter';
	font-weight: 300;
	src: local('Inter'), url('../assets/fonts/Inter-Light.ttf') format('truetype'); }

@font-face {
	font-family: 'Inter';
	font-weight: 400;
	src: local('Inter'), url('../assets/fonts/Inter-Regular.ttf') format('truetype'); }

@font-face {
	font-family: 'Inter';
	font-weight: 500;
	src: local('Inter'), url('../assets/fonts/Inter-Medium.ttf') format('truetype'); }

@font-face {
	font-family: 'Inter';
	font-weight: 600;
	src: local('Inter'), url('../assets/fonts/Inter-SemiBold.ttf') format('truetype'); }

@font-face {
	font-family: 'Inter';
	font-weight: 700;
	src: local('Inter'), url('../assets/fonts/Inter-Bold.ttf') format('truetype'); }

@font-face {
	font-family: 'Inter';
	font-weight: 800;
	src: local('Inter'), url('../assets/fonts/Inter-ExtraBold.ttf') format('truetype'); }

@font-face {
	font-family: 'Inter';
	font-weight: 900;
	src: local('Inter'), url('../assets/fonts/Inter-Black.ttf') format('truetype'); }

@font-face {
	font-family: 'Inter';
	font-weight: normal;
	src: local('Inter'), url('../assets/fonts/Inter-Regular.ttf') format('truetype'); }

* {
	font-family: $font-family;
	font-feature-settings: 'ss02' !important;
	font-variant-numeric: slashed-zero!important; }

html {
	scroll-behavior: smooth; }

.suspense-loading-spinner {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-spinner;
	background: $backgroundColor-zinc-200;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center; }

.text-button {
	background: none;
	border: none;
	cursor: pointer; }

::-webkit-scrollbar {
    background-color: #fff;
    width: 16px; }

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: #fff; }

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff; }

::-webkit-scrollbar-thumb:hover {
	background-color: #8c8c91; }

.mainfolder-plans-button-row, .subfolder-plans-button-row, {
	min-height: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-evenly; }

@media(max-width: 1650px) {
	.mainfolder-plans-button-row {
		.flex100 {
			width: 100%; } }

	.mainfolder-plans-button-row > div {
		display: flex;
		flex-basis: calc(50% - 40px);
		justify-content: center;
		flex-direction: column; }

	.mainfolder-plans-button-row > div > div {
		display: flex;
		justify-content: center;
		flex-direction: row; } }
