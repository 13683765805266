@import 'tailwind-variables';
@import 'variables';

.ant-form-item {
	.ant-input-password.extd-password-input {
		border-radius: 4px; }
	&.ant-form-item-has-error {
		.ant-input-password.extd-password-input {
			border: 1px solid $borderColor-error-medium;
			color: $textColor-error-medium;
			&::placeholder {
				color: $textColor-error-medium; }
			&:hover, &:focus {
				border: 1px solid $borderColor-error-medium;
				box-shadow: none; } }
		.ant-form-item-explain-error {
			color: $textColor-error-medium; } } }

.ant-form-item:not(.ant-form-item-has-error) {
	.ant-input-password.extd-password-input {
		&:hover {
			border: 1px solid $borderColor-blue-600; }
		&:focus {
			border: 1px solid $borderColor-blue-600;
			box-shadow: $field-shadow-focus-default; } }
	.ant-input-affix-wrapper-focused.extd-password-input, .ant-input-affix-wrapper.extd-password-input:focus {
		border: 1px solid $borderColor-blue-600 !important;
		box-shadow: $field-shadow-focus-default; } }
