@import 'tailwind-variables';
@import 'variables';

h1, h2, h3, h4 {
	padding: 0;
	margin: 0; }

.heading-1 {
	font-size: 40px;
	line-height: 120%;
	font-weight: 800;
	color: $textColor-zinc-900; }
.heading-2 {
	font-size: 32px;
	line-height: 120%;
	font-weight: 700;
	color: $textColor-zinc-900; }
.heading-3 {
	font-size: 24px;
	line-height: 120%;
	font-weight: 700;
	color: $textColor-zinc-900;
	cursor: default; }
.heading-4 {
	font-size: 20px;
	line-height: 120%;
	font-weight: 700;
	color: $textColor-zinc-900; }
.text-l-regular {
	font-size: 16px;
	line-height: 160%;
	font-weight: 400;
	color: $textColor-zinc-900; }
.text-l-medium {
	font-size: 16px;
	line-height: 160%;
	font-weight: 500;
	color: $textColor-zinc-900; }
.text-l-bold {
	font-size: 16px;
	line-height: 160%;
	font-weight: 700;
	color: $textColor-zinc-900; }
.text-m-regular {
	font-size: 14px;
	line-height: 160%;
	font-weight: 400;
	color: $textColor-zinc-900; }
.text-m-medium {
	font-size: 14px;
	line-height: 160%;
	font-weight: 500;
	color: $textColor-zinc-900; }
.text-m-bold {
	font-size: 14px;
	line-height: 160%;
	font-weight: 700;
	color: $textColor-zinc-900; }
.text-s-regular {
	font-size: 12px;
	line-height: 160%;
	font-weight: 400;
	color: $textColor-zinc-900; }
.text-s-medium {
	font-size: 12px;
	line-height: 160%;
	font-weight: 500;
	color: $textColor-zinc-900; }
.text-s-bold {
	font-size: 12px;
	line-height: 160%;
	font-weight: 700;
	color: $textColor-zinc-900; }
