@import 'tailwind-variables';
@import 'variables';

// Header
.extd-layout-header {
	position: fixed;
	padding-left: 0;
	padding-right: 0;
	top: 0;
	width: 100%;
	background: $backgroundColor-white;
	display: flex;
	justify-content: flex-start;
	height: $header-height;
	box-shadow: 0px 2px 4px $backgroundColor-zinc-200;
	z-index: $z-index-header;

	.extd-sider-header {
		display: flex;
		height: $header-height;
		width: $sidebar-width;
		align-items: center;
		background: $backgroundColor-zinc-100;
		border-bottom: 1px solid $backgroundColor-zinc-200;
		box-shadow: 0px 3px 3px $backgroundColor-zinc-200; } }

// Contents
.extd-layout {
	min-height: 100vh;
	background: $backgroundColor-zinc-100;
	&.with-footer {
		.content-body {
			margin-bottom: $footer-height + 24px; } }

	.content-body {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 80px;
		&.medium {
			width: $content-md-width; } }

	.content-header {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		&.with-sidebar {
			display: flex;
			width: calc(100% - 240px); // - sidebar-width
			justify-content: space-between; }
		&.medium {
			width: $content-md-width; } } }

// Footer
.extd-layout-footer {
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	background: $backgroundColor-white;
	border: none;
	box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
	height: $footer-height;
	padding: 0;
	z-index: $z-index-footer;
	width: 100%;
	.content-footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: $content-md-width;
		height: $footer-height;
		margin-left: auto;
		margin-right: auto;
		button {
			margin-left: $margin-2; } }
	.extd-pagination {
		position: absolute;
		right: $margin-4;
		top: 18px; } }

// Siders
.extd-projects-sider-scroll {
	position: fixed;
	top: $header-height;
	bottom: 0;
	z-index: $z-index-sider;
	background: $backgroundColor-zinc-100;
	width: $sidebar-width; }

.extd-projects-sider-actions {
	&.is-active {
		opacity: 1;
		background-color: $backgroundColor-blue-200;
		transition: opacity 0.5s linear;
		&:hover {
			background-color: $backgroundColor-blue-300; } }
	&.is-disabled {
		opacity: 0; } }

.extd-projects-sider-version {
	position: absolute;
	bottom: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: $sidebar-width; }
