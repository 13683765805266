@import 'tailwind-variables';

// Sizes
.ant-modal {
	width: calc(420px + 10%)!important;
	&.modal-w-1100 {
		width: 1100px!important; } }

// Content
.ant-modal-content {
	border-radius: 4px;

	.ant-modal-close-x {
		padding-top: 4px;
		padding-right: 24px;
		height: 64px !important; }

	.ant-modal-header {
		display: flex;
		align-items: center;
		border-radius: 4px 4px 0 0;
		height: 64px;
		padding: 24px; }

	.ant-modal-title {
		font-size: 20px;
		font-weight: bold; }

	.ant-modal-footer {
		padding: 24px;
		border-radius: 0 0 4px 4px;
		border-top: none; } }
