// @import 'tailwind-variables'
// @import 'variables'

.ant-form-item {
	.ant-upload.ant-upload-drag.extd-upload {
		width: 100%; }

	.ant-upload-list.extd-upload {
		position: absolute;
		top: 20px;
		left: 20px;
		width: 300px;
		.ant-upload-list-item {
			height: 40px;
			width: 320px;
			margin-bottom: -4px;
			border-radius: 4px;
			background-color: $backgroundColor-gray-100; } }

	.ant-upload-list-item-card-actions-btn.ant-btn-sm {
		height: 24px;
		width: 24px; } }

.extd-drag-upload {
	.ant-upload-list {
		position: absolute;
		top: -8px;
		left: 0px;
		width: 50%;
		.ant-upload-list-item {
			height: 40px;
			width: calc(100% - 16px);
			margin-bottom: -4px;
			border-radius: 4px;
			background-color: $backgroundColor-gray-100; } }

	.ant-upload-list-item-card-actions-btn.ant-btn-sm {
		height: 24px;
		width: 24px; } }

.extd-select-upload {
	.ant-upload.ant-upload-select {
		position: relative;
		right: -195px; }

	.ant-upload-list {
		position: relative;
		width: 300px;
		right: 20px;
		.ant-upload-list-item {
			height: 40px;
			width: 320px;
			margin-bottom: -4px;
			border-radius: 4px;
			background-color: $backgroundColor-gray-100; } } }

.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon, .ant-upload-list-item-error .ant-upload-list-item-name, .ant-upload-list-item-error .ant-upload-text-icon>.anticon {
	color: $textColor-error-medium; }

.handle-on {
	cursor: grab;
	svg {
		color: $borderColor-gray-300; } }

.sortable-chosen {
	.handle-on {
		cursor: grabbing; } }

.grabbing {
	cursor: grabbing !important; }
