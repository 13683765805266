@import 'tailwind-variables';
@import 'variables';

.ant-tree.extd-tree {
	background-color: $backgroundColor-zinc-100;
	overflow: auto;
	// - siderHeader + Projects/Archive tabs + version
	height: calc(100vh - 188px);
	&.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
		color: $backgroundColor-blue-600;
		.ant-typography {
			color: $backgroundColor-blue-600; } }
	&.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
		color: $backgroundColor-blue-600; }
	.ant-tree-treenode {
		padding-top: 2px;
		height: 32px; } }
// oddelene lebo inak mi nieco pretazuje border-right
.ant-tree.extd-tree.ant-tree-directory .ant-tree-treenode-selected:before {
	background-color: $backgroundColor-blue-200 !important;
	border-right: 3px solid $backgroundColor-blue-600 !important; }
