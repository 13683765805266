@import 'tailwind-variables';
@import 'typography';

.ant-btn.extd-btn,
.ant-modal-footer .ant-btn {
	height: 39px;

	&.ant-btn-primary {
		box-shadow: none;
		color: $backgroundColor-white;
		background-color: $backgroundColor-blue-600;
		border-radius: 4px;
		&:hover {
			background-color: $backgroundColor-blue-700; }
		&:disabled {
			background-color: $backgroundColor-white;
			color: $textColor-zinc-400;
			border: 1px solid $borderColor-zinc-400; } }

	&.ant-btn-default {
		box-shadow: none;
		color: $backgroundColor-blue-600;
		background-color: $backgroundColor-white;
		border-radius: 4px;
		border: 1px solid $backgroundColor-blue-600;
		&.download {
			position: fixed;
			width: 400px;
			z-index: 11;
			right: 15px;
			bottom: 70px;
			border: none;
			background-color: $backgroundColor-zinc-100;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			color: $textColor-zinc-600; }
		&:hover {
			background-color: $backgroundColor-blue-100; }
		&:disabled {
			background-color: $backgroundColor-white;
			color: $textColor-zinc-400;
			border: 1px solid $borderColor-zinc-400; } }

	&.ant-btn-icon-only {
		width: 39px;
		height: 39px; } }

.extd-btn.ant-btn-text.ant-btn-dangerous {
	height: 39px;
	> svg:first-child {
		position: absolute;
		top: 50%;
		transform: translateY(-50%); }
	> span {
		font-size: 14px;
		line-height: 160%;
		font-weight: 400;
		color: $textColor-error-medium;
		margin-left: 25px;
		&:hover {
			color: $textColor-error-medium; } } }

.extd-btn-danger {
	height: 39px;
	border: 1px solid $backgroundColor-error-dark !important;

	&.ant-btn-primary {
		box-shadow: none;
		color: $backgroundColor-white;
		background-color: $backgroundColor-error-medium;
		border-radius: 4px;

		&:hover {
			background-color: $backgroundColor-error-dark; } } }
