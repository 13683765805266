@import 'tailwind-variables';
@import 'typography';

.ant-picker {
	&.extd-picker {
		border-radius: 4px;
		color: $backgroundColor-white;
		height: $height-10;
		background-color: $backgroundColor-blue-600;
		.ant-picker-input>input::placeholder {
			color: $backgroundColor-white; }
		.ant-picker-input>input {
			color: $backgroundColor-white; } }
	&.extd-picker-dropdown {
		border-radius: 4px;
		height: $height-10;
		&.ant-picker-focused {
			border: 1px solid $borderColor-blue-600 !important;
			box-shadow: $field-shadow-focus-default; }
		&:hover {
			border: 1px solid $borderColor-blue-600; } } }

.ant-picker-today-btn {
	color: $textColor-blue-600; }

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 1px solid $borderColor-blue-600 !important; }

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
	background: $backgroundColor-blue-600; }
