@import 'tailwind-variables';

.download-window {
	position: fixed;
	background-color: $backgroundColor-white;
	transition: height 0.5s;
	width: 400px;
	right: 15px;
	z-index: 11;
	border-radius: 4px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15); }

.download-overlay {
	background-color: rgba(255,255,255,0.9);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	overflow: hidden;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	padding: 16px; }
