@import 'tailwind-variables';
@import 'variables';

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
	padding: 0 $padding-3; }
.ant-menu-horizontal>.ant-menu-item:after, .ant-menu-horizontal>.ant-menu-submenu:after {
	left: $padding-3;
	right: $padding-3; }

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover {
	color: $textColor-blue-600; }

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after {
	border-bottom: 2px solid $backgroundColor-blue-600; }

.ant-menu-submenu-selected,
.ant-menu-item-selected,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-submenu-title:hover {
	color: $textColor-blue-600; }

.extd-projects {
	.ant-menu-item-selected {
		background-color: $backgroundColor-blue-200 !important; } }

.ant-menu-inline .ant-menu-item:after {
	border-right: 3px solid $textColor-blue-600; }

.ant-menu-submenu-open {
	background-color: $backgroundColor-blue-200 !important; }

.ant-menu-submenu:hover {
	.ant-menu-submenu-arrow:before, .ant-menu-submenu-arrow:after {
		color: $textColor-blue-600 !important; } }

.extd-settings {
	.ant-menu-item {
		border-radius: 4px; }
	.ant-menu-item-selected {
		background-color: $backgroundColor-blue-200 !important;
		color: $textColor-blue-600 !important; }
	.ant-menu-item-active {
		background-color: $backgroundColor-blue-100; } }
