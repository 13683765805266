@import 'tailwind-variables';
@import 'variables';

.extd-table-dropdown {
	cursor: pointer;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	&:hover {
		background-color: $backgroundColor-blue-200; } }

.extd-table {
	.ant-table-thead > tr > th {
		background-color: $backgroundColor-zinc-100; }
	.ant-pagination-item {
		border-radius: 4px;
		&:hover {
			> a {
				color: $backgroundColor-blue-600; } } }
	.ant-pagination-item-active {
		border-color: $backgroundColor-blue-600;
		> a {
			color: $backgroundColor-blue-600; } }
	.ant-pagination-next:not(.ant-pagination-disabled), .ant-pagination-prev:not(.ant-pagination-disabled) {
		&:hover > button > span {
	 		color: $backgroundColor-blue-600; } }
	table {
		tbody {
			tr.ant-table-row {
				&:hover > td {
					background-color: $backgroundColor-blue-100;
					background: $backgroundColor-blue-100; } }
			tr.ant-table-row-selected {
				td {
					background: $backgroundColor-blue-200 !important; } } } }
	.ant-table-sticky-scroll-bar {
		margin-bottom: $margin-32; } }
// overrides also focus/hover
.ant-checkbox {
	.ant-checkbox-inner {
		border-radius: 4px;
		border-color: $borderColor-blue-600 !important; } }

.ant-checkbox-indeterminate {
	.ant-checkbox-inner:after {
		border-radius: 4px;
		background-color: $backgroundColor-blue-600 !important; } }


.ant-checkbox-wrapper {
	.ant-checkbox-inner {
		border-radius: 4px; }
	&.ant-checkbox-wrapper-checked {
		.ant-checkbox-inner {
			background-color: $backgroundColor-blue-600 !important;
			border-color: $backgroundColor-blue-600 !important; }
		&:after {
			border-radius: 4px;
			border-color: $backgroundColor-blue-600 !important; } } }

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: $backgroundColor-blue-600 !important; }

.extd-pagination {
	.ant-pagination-item {
		border-radius: 4px;
		&:hover {
			> a {
				color: $backgroundColor-blue-600; } } }
	.ant-pagination-item-active {
		border-color: $backgroundColor-blue-600;
		> a {
			color: $backgroundColor-blue-600; } }
	.ant-pagination-next:not(.ant-pagination-disabled), .ant-pagination-prev:not(.ant-pagination-disabled) {
		&:hover > button > span {
	 		color: $backgroundColor-blue-600; } }

	.ant-pagination-options {
		.ant-select {
			.ant-select-selector {
				border-radius: 4px;
				&:hover {
					border: 1px solid $borderColor-blue-600; } }
			&.ant-select-focused .ant-select-selector {
				border: 1px solid $borderColor-blue-600;
				box-shadow: $boxShadowColor-blue-600 !important; }
			&:focus {
				border: 1px solid $borderColor-blue-600 {
	 			box-shadow: $boxShadowColor-blue-600 !important; } }
			.ant-select-item-option-selected {
				background-color: $backgroundColor-blue-100; } } } }
