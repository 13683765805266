@import 'tailwind-variables';
@import 'variables';

.extd-radio {
	display: flex;
	direction: row; }

.ant-radio-button-wrapper:first-child {
	border-radius: 4px 0px 0px 4px !important;
	&:hover {
		color: $textColor-blue-600; } }

.ant-radio-button-wrapper:last-child {
	border-radius: 0px 4px 4px 0px !important;
	&:hover {
		color: $textColor-blue-600; } }

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	background-color: $backgroundColor-blue-600;
	border-color: $borderColor-blue-600;
	&:hover {
		background-color: $backgroundColor-blue-700;
		border-color: $borderColor-blue-600; } }
