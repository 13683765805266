@import 'tailwind-variables';
@import 'variables';


.ant-select.extd-select {
	width: 100%;
	.ant-select-selector {
		border-radius: 4px; }
	&:hover .ant-select-selector {
		border: 1px solid $borderColor-blue-600; } }

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border: 1px solid $borderColor-blue-600;
	box-shadow: $boxShadowColor-blue-600 !important; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: $backgroundColor-blue-200 !important; }
