/*@tailwind base; !* Zakladne CSS pre pracu roznych prehliadacoch - moze prepisat nieco z antu *!*/

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

/* dekorator pre custom css komponenty - apply css1 css2 css3.... */

#tailwind .pointer-events-none {
  pointer-events: none
}

#tailwind .pointer-events-auto {
  pointer-events: auto
}

#tailwind .visible {
  visibility: visible
}

#tailwind .static {
  position: static
}

#tailwind .fixed {
  position: fixed
}

#tailwind .absolute {
  position: absolute
}

#tailwind .relative {
  position: relative
}

#tailwind .sticky {
  position: -webkit-sticky;
  position: sticky
}

#tailwind .right-2 {
  right: 0.5rem
}

#tailwind .m-4 {
  margin: 1rem
}

#tailwind .m-0 {
  margin: 0px
}

#tailwind .m-3 {
  margin: 0.75rem
}

#tailwind .mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

#tailwind .mt-2 {
  margin-top: 0.5rem
}

#tailwind .mb-2 {
  margin-bottom: 0.5rem
}

#tailwind .mt-0 {
  margin-top: 0px
}

#tailwind .mb-0 {
  margin-bottom: 0px
}

#tailwind .ml-2 {
  margin-left: 0.5rem
}

#tailwind .mt-10 {
  margin-top: 2.5rem
}

#tailwind .mr-4 {
  margin-right: 1rem
}

#tailwind .ml-4 {
  margin-left: 1rem
}

#tailwind .ml-3 {
  margin-left: 0.75rem
}

#tailwind .mb-4 {
  margin-bottom: 1rem
}

#tailwind .mr-2 {
  margin-right: 0.5rem
}

#tailwind .mr-5 {
  margin-right: 1.25rem
}

#tailwind .mt-1 {
  margin-top: 0.25rem
}

#tailwind .mt-3 {
  margin-top: 0.75rem
}

#tailwind .mt-4 {
  margin-top: 1rem
}

#tailwind .mr-1 {
  margin-right: 0.25rem
}

#tailwind .ml-1 {
  margin-left: 0.25rem
}

#tailwind .mt-20 {
  margin-top: 5rem
}

#tailwind .mb-10 {
  margin-bottom: 2.5rem
}

#tailwind .mb-6 {
  margin-bottom: 1.5rem
}

#tailwind .mb-28 {
  margin-bottom: 7rem
}

#tailwind .mb-8 {
  margin-bottom: 2rem
}

#tailwind .mt-6 {
  margin-top: 1.5rem
}

#tailwind .mb-1 {
  margin-bottom: 0.25rem
}

#tailwind .mt-24 {
  margin-top: 6rem
}

#tailwind .mb-5 {
  margin-bottom: 1.25rem
}

#tailwind .mt-5 {
  margin-top: 1.25rem
}

#tailwind .mt-8 {
  margin-top: 2rem
}

#tailwind .ml-6 {
  margin-left: 1.5rem
}

#tailwind .mt-16 {
  margin-top: 4rem
}

#tailwind .block {
  display: block
}

#tailwind .inline {
  display: inline
}

#tailwind .flex {
  display: flex
}

#tailwind .table {
  display: table
}

#tailwind .hidden {
  display: none
}

#tailwind .h-16 {
  height: 4rem
}

#tailwind .h-screen {
  height: 100vh
}

#tailwind .h-6 {
  height: 1.5rem
}

#tailwind .h-14 {
  height: 3.5rem
}

#tailwind .h-10 {
  height: 2.5rem
}

#tailwind .h-9 {
  height: 2.25rem
}

#tailwind .h-7 {
  height: 1.75rem
}

#tailwind .w-full {
  width: 100%
}

#tailwind .w-16 {
  width: 4rem
}

#tailwind .w-6 {
  width: 1.5rem
}

#tailwind .w-48 {
  width: 12rem
}

#tailwind .w-52 {
  width: 13rem
}

#tailwind .w-60 {
  width: 15rem
}

#tailwind .w-56 {
  width: 14rem
}

#tailwind .w-9 {
  width: 2.25rem
}

#tailwind .w-36 {
  width: 9rem
}

#tailwind .w-32 {
  width: 8rem
}

#tailwind .w-40 {
  width: 10rem
}

#tailwind .w-80 {
  width: 20rem
}

#tailwind .cursor-pointer {
  cursor: pointer
}

#tailwind .cursor-default {
  cursor: default
}

#tailwind .resize {
  resize: both
}

#tailwind .flex-row {
  flex-direction: row
}

#tailwind .flex-row-reverse {
  flex-direction: row-reverse
}

#tailwind .flex-col {
  flex-direction: column
}

#tailwind .items-center {
  align-items: center
}

#tailwind .justify-start {
  justify-content: flex-start
}

#tailwind .justify-center {
  justify-content: center
}

#tailwind .justify-between {
  justify-content: space-between
}

#tailwind .gap-2 {
  gap: 0.5rem
}

#tailwind .overflow-hidden {
  overflow: hidden
}

#tailwind .text-ellipsis {
  text-overflow: ellipsis
}

#tailwind .rounded {
  border-radius: 0.25rem
}

#tailwind .border {
  border-width: 1px
}

#tailwind .border-0 {
  border-width: 0px
}

#tailwind .border-r-4 {
  border-right-width: 4px
}

#tailwind .border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(47 84 235 / var(--tw-border-opacity))
}

#tailwind .bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 255 / var(--tw-bg-opacity))
}

#tailwind .bg-zinc-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity))
}

#tailwind .bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 35 158 / var(--tw-bg-opacity))
}

#tailwind .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

#tailwind .bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(173 198 255 / var(--tw-bg-opacity))
}

#tailwind .bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 228 255 / var(--tw-bg-opacity))
}

#tailwind .bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

#tailwind .bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

#tailwind .bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity))
}

#tailwind .p-2 {
  padding: 0.5rem
}

#tailwind .p-0 {
  padding: 0px
}

#tailwind .p-4 {
  padding: 1rem
}

#tailwind .px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

#tailwind .pt-5 {
  padding-top: 1.25rem
}

#tailwind .pt-1 {
  padding-top: 0.25rem
}

#tailwind .pl-2 {
  padding-left: 0.5rem
}

#tailwind .pr-2 {
  padding-right: 0.5rem
}

#tailwind .pb-2 {
  padding-bottom: 0.5rem
}

#tailwind .pb-1 {
  padding-bottom: 0.25rem
}

#tailwind .pt-28 {
  padding-top: 7rem
}

#tailwind .pt-4 {
  padding-top: 1rem
}

#tailwind .pb-4 {
  padding-bottom: 1rem
}

#tailwind .pt-2 {
  padding-top: 0.5rem
}

#tailwind .text-center {
  text-align: center
}

#tailwind .text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

#tailwind .font-medium {
  font-weight: 500
}

#tailwind .uppercase {
  text-transform: uppercase
}

#tailwind .lowercase {
  text-transform: lowercase
}

#tailwind .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(47 84 235 / var(--tw-text-opacity))
}

#tailwind .text-zinc-400 {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity))
}

#tailwind .text-warning-medium {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity))
}

#tailwind .text-success-medium {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity))
}

#tailwind .text-error-medium {
  --tw-text-opacity: 1;
  color: rgb(200 41 41 / var(--tw-text-opacity))
}

#tailwind .text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity))
}

#tailwind .text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity))
}

#tailwind .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

#tailwind .blur {
  --tw-blur: blur(8px);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

#tailwind .filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

#tailwind .transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

/* utilky pre CSS classy - marginy, apdding, colors, spacing... */

#tailwind .hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
