@import 'tailwind-variables';
@import 'variables';

.extd-switch-button {
	display: flex;
	cursor: pointer;
	justify-content: space-between;
	align-items: center;
	height: 39px;
	border-radius: 4px;
	padding: 8px;
	.ant-switch-handle {
		height: 35px !important; } }

.ant-switch {
	background-color: $backgroundColor-gray-300; }
.ant-switch.ant-switch-checked {
	background-color: $backgroundColor-blue-600; }

