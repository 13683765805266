/* Converted Tailwind Config to sass */
$columns-1: 1;
$columns-2: 2;
$columns-3: 3;
$columns-4: 4;
$columns-5: 5;
$columns-6: 6;
$columns-7: 7;
$columns-8: 8;
$columns-9: 9;
$columns-10: 10;
$columns-11: 11;
$columns-12: 12;
$columns-auto: auto;
$columns-3xs: 16rem;
$columns-2xs: 18rem;
$columns-xs: 20rem;
$columns-sm: 24rem;
$columns-md: 28rem;
$columns-lg: 32rem;
$columns-xl: 36rem;
$columns-2xl: 42rem;
$columns-3xl: 48rem;
$columns-4xl: 56rem;
$columns-5xl: 64rem;
$columns-6xl: 72rem;
$columns-7xl: 80rem;

$animation-none: none;
$animation-spin: spin 1s linear infinite;
$animation-ping: (ping 1s cubic-bezier(0, 0, 0.2, 1) infinite);
$animation-pulse: (pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite);
$animation-bounce: bounce 1s infinite;

$aspectRatio-auto: auto;
$aspectRatio-square: 1 / 1;
$aspectRatio-video: 16 / 9;

$backdropBlur-0: 0;
$backdropBlur-none: 0;
$backdropBlur-sm: 4px;
$backdropBlur-DEFAULT: 8px;
$backdropBlur-md: 12px;
$backdropBlur-lg: 16px;
$backdropBlur-xl: 24px;
$backdropBlur-2xl: 40px;
$backdropBlur-3xl: 64px;

$backdropBrightness-0: 0;
$backdropBrightness-50: .5;
$backdropBrightness-75: .75;
$backdropBrightness-90: .9;
$backdropBrightness-95: .95;
$backdropBrightness-100: 1;
$backdropBrightness-105: 1.05;
$backdropBrightness-110: 1.1;
$backdropBrightness-125: 1.25;
$backdropBrightness-150: 1.5;
$backdropBrightness-200: 2;

$backdropContrast-0: 0;
$backdropContrast-50: .5;
$backdropContrast-75: .75;
$backdropContrast-100: 1;
$backdropContrast-125: 1.25;
$backdropContrast-150: 1.5;
$backdropContrast-200: 2;

$backdropGrayscale-0: 0;
$backdropGrayscale-DEFAULT: 100%;

$backdropHueRotate-0: 0deg;
$backdropHueRotate-15: 15deg;
$backdropHueRotate-30: 30deg;
$backdropHueRotate-60: 60deg;
$backdropHueRotate-90: 90deg;
$backdropHueRotate-180: 180deg;

$backdropInvert-0: 0;
$backdropInvert-DEFAULT: 100%;

$backdropOpacity-0: 0;
$backdropOpacity-5: 0.05;
$backdropOpacity-10: 0.1;
$backdropOpacity-20: 0.2;
$backdropOpacity-25: 0.25;
$backdropOpacity-30: 0.3;
$backdropOpacity-40: 0.4;
$backdropOpacity-50: 0.5;
$backdropOpacity-60: 0.6;
$backdropOpacity-70: 0.7;
$backdropOpacity-75: 0.75;
$backdropOpacity-80: 0.8;
$backdropOpacity-90: 0.9;
$backdropOpacity-95: 0.95;
$backdropOpacity-100: 1;

$backdropSaturate-0: 0;
$backdropSaturate-50: .5;
$backdropSaturate-100: 1;
$backdropSaturate-150: 1.5;
$backdropSaturate-200: 2;

$backdropSepia-0: 0;
$backdropSepia-DEFAULT: 100%;

$backgroundColor-transparent: transparent;
$backgroundColor-current: currentColor;
$backgroundColor-error-ultralight: #FEF2F2;
$backgroundColor-error-light: #FEE2E2;
$backgroundColor-error-medium: #C82929;
$backgroundColor-error-dark: #991B1B;
$backgroundColor-error-ultradark: #7F1D1D;
$backgroundColor-success-light: #DCFCE7;
$backgroundColor-success-medium: #15803D;
$backgroundColor-success-dark: #14532D;
$backgroundColor-warning-light: #FEF3C7;
$backgroundColor-warning-medium: #D97706;
$backgroundColor-warning-dark: #92400E;
$backgroundColor-black: #000;
$backgroundColor-white: #fff;
$backgroundColor-gray-50: #f9fafb;
$backgroundColor-gray-100: #f3f4f6;
$backgroundColor-gray-200: #e5e7eb;
$backgroundColor-gray-300: #d1d5db;
$backgroundColor-gray-400: #9ca3af;
$backgroundColor-gray-500: #6b7280;
$backgroundColor-gray-600: #4b5563;
$backgroundColor-gray-700: #374151;
$backgroundColor-gray-800: #1f2937;
$backgroundColor-gray-900: #111827;
$backgroundColor-zinc-50: #fafafa;
$backgroundColor-zinc-100: #f4f4f5;
$backgroundColor-zinc-200: #e4e4e7;
$backgroundColor-zinc-300: #d4d4d8;
$backgroundColor-zinc-400: #a1a1aa;
$backgroundColor-zinc-500: #71717a;
$backgroundColor-zinc-600: #52525b;
$backgroundColor-zinc-700: #3f3f46;
$backgroundColor-zinc-800: #27272a;
$backgroundColor-zinc-900: #18181b;
$backgroundColor-red-50: #fef2f2;
$backgroundColor-red-100: #fee2e2;
$backgroundColor-red-200: #fecaca;
$backgroundColor-red-300: #fca5a5;
$backgroundColor-red-400: #f87171;
$backgroundColor-red-500: #ef4444;
$backgroundColor-red-600: #dc2626;
$backgroundColor-red-700: #b91c1c;
$backgroundColor-red-800: #991b1b;
$backgroundColor-red-900: #7f1d1d;
$backgroundColor-yellow-50: #fffbeb;
$backgroundColor-yellow-100: #fef3c7;
$backgroundColor-yellow-200: #fde68a;
$backgroundColor-yellow-300: #fcd34d;
$backgroundColor-yellow-400: #fbbf24;
$backgroundColor-yellow-500: #f59e0b;
$backgroundColor-yellow-600: #d97706;
$backgroundColor-yellow-700: #b45309;
$backgroundColor-yellow-800: #92400e;
$backgroundColor-yellow-900: #78350f;
$backgroundColor-green-50: #f0fdf4;
$backgroundColor-green-100: #dcfce7;
$backgroundColor-green-200: #bbf7d0;
$backgroundColor-green-300: #86efac;
$backgroundColor-green-400: #4ade80;
$backgroundColor-green-500: #22c55e;
$backgroundColor-green-600: #16a34a;
$backgroundColor-green-700: #15803d;
$backgroundColor-green-800: #166534;
$backgroundColor-green-900: #14532d;
$backgroundColor-blue-100: #F0F5FF;
$backgroundColor-blue-200: #D6E4FF;
$backgroundColor-blue-300: #ADC6FF;
$backgroundColor-blue-400: #85A5FF;
$backgroundColor-blue-500: #597EF7;
$backgroundColor-blue-600: #2F54EB;
$backgroundColor-blue-700: #1D39C4;
$backgroundColor-blue-800: #10239E;
$backgroundColor-blue-900: #061178;
$backgroundColor-indigo-50: #eef2ff;
$backgroundColor-indigo-100: #e0e7ff;
$backgroundColor-indigo-200: #c7d2fe;
$backgroundColor-indigo-300: #a5b4fc;
$backgroundColor-indigo-400: #818cf8;
$backgroundColor-indigo-500: #6366f1;
$backgroundColor-indigo-600: #4f46e5;
$backgroundColor-indigo-700: #4338ca;
$backgroundColor-indigo-800: #3730a3;
$backgroundColor-indigo-900: #312e81;
$backgroundColor-purple-50: #f5f3ff;
$backgroundColor-purple-100: #ede9fe;
$backgroundColor-purple-200: #ddd6fe;
$backgroundColor-purple-300: #c4b5fd;
$backgroundColor-purple-400: #a78bfa;
$backgroundColor-purple-500: #8b5cf6;
$backgroundColor-purple-600: #7c3aed;
$backgroundColor-purple-700: #6d28d9;
$backgroundColor-purple-800: #5b21b6;
$backgroundColor-purple-900: #4c1d95;
$backgroundColor-pink-50: #fdf2f8;
$backgroundColor-pink-100: #fce7f3;
$backgroundColor-pink-200: #fbcfe8;
$backgroundColor-pink-300: #f9a8d4;
$backgroundColor-pink-400: #f472b6;
$backgroundColor-pink-500: #ec4899;
$backgroundColor-pink-600: #db2777;
$backgroundColor-pink-700: #be185d;
$backgroundColor-pink-800: #9d174d;
$backgroundColor-pink-900: #831843;
$backgroundColor-emerald-50: #ecfdf5;
$backgroundColor-emerald-100: #d1fae5;
$backgroundColor-emerald-200: #a7f3d0;
$backgroundColor-emerald-300: #6ee7b7;
$backgroundColor-emerald-400: #34d399;
$backgroundColor-emerald-500: #10b981;
$backgroundColor-emerald-600: #059669;
$backgroundColor-emerald-700: #047857;
$backgroundColor-emerald-800: #065f46;
$backgroundColor-emerald-900: #064e3b;

$backgroundImage-none: none;
$backgroundImage-gradient-to-t: (linear-gradient(to top, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-tr: (linear-gradient(to top right, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-r: (linear-gradient(to right, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-br: (linear-gradient(to bottom right, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-b: (linear-gradient(to bottom, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-bl: (linear-gradient(to bottom left, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-l: (linear-gradient(to left, var(--tw-gradient-stops)));
$backgroundImage-gradient-to-tl: (linear-gradient(to top left, var(--tw-gradient-stops)));

$backgroundOpacity-0: 0;
$backgroundOpacity-5: 0.05;
$backgroundOpacity-10: 0.1;
$backgroundOpacity-20: 0.2;
$backgroundOpacity-25: 0.25;
$backgroundOpacity-30: 0.3;
$backgroundOpacity-40: 0.4;
$backgroundOpacity-50: 0.5;
$backgroundOpacity-60: 0.6;
$backgroundOpacity-70: 0.7;
$backgroundOpacity-75: 0.75;
$backgroundOpacity-80: 0.8;
$backgroundOpacity-90: 0.9;
$backgroundOpacity-95: 0.95;
$backgroundOpacity-100: 1;

$backgroundPosition-bottom: bottom;
$backgroundPosition-center: center;
$backgroundPosition-left: left;
$backgroundPosition-left-bottom: left bottom;
$backgroundPosition-left-top: left top;
$backgroundPosition-right: right;
$backgroundPosition-right-bottom: right bottom;
$backgroundPosition-right-top: right top;
$backgroundPosition-top: top;

$backgroundSize-auto: auto;
$backgroundSize-cover: cover;
$backgroundSize-contain: contain;

$blur-0: 0;
$blur-none: 0;
$blur-sm: 4px;
$blur-DEFAULT: 8px;
$blur-md: 12px;
$blur-lg: 16px;
$blur-xl: 24px;
$blur-2xl: 40px;
$blur-3xl: 64px;

$brightness-0: 0;
$brightness-50: .5;
$brightness-75: .75;
$brightness-90: .9;
$brightness-95: .95;
$brightness-100: 1;
$brightness-105: 1.05;
$brightness-110: 1.1;
$brightness-125: 1.25;
$brightness-150: 1.5;
$brightness-200: 2;

$borderColor-transparent: transparent;
$borderColor-current: currentColor;
$borderColor-error-ultralight: #FEF2F2;
$borderColor-error-light: #FEE2E2;
$borderColor-error-medium: #C82929;
$borderColor-error-dark: #991B1B;
$borderColor-error-ultradark: #7F1D1D;
$borderColor-success-light: #DCFCE7;
$borderColor-success-medium: #15803D;
$borderColor-success-dark: #14532D;
$borderColor-warning-light: #FEF3C7;
$borderColor-warning-medium: #D97706;
$borderColor-warning-dark: #92400E;
$borderColor-black: #000;
$borderColor-white: #fff;
$borderColor-gray-50: #f9fafb;
$borderColor-gray-100: #f3f4f6;
$borderColor-gray-200: #e5e7eb;
$borderColor-gray-300: #d1d5db;
$borderColor-gray-400: #9ca3af;
$borderColor-gray-500: #6b7280;
$borderColor-gray-600: #4b5563;
$borderColor-gray-700: #374151;
$borderColor-gray-800: #1f2937;
$borderColor-gray-900: #111827;
$borderColor-zinc-50: #fafafa;
$borderColor-zinc-100: #f4f4f5;
$borderColor-zinc-200: #e4e4e7;
$borderColor-zinc-300: #d4d4d8;
$borderColor-zinc-400: #a1a1aa;
$borderColor-zinc-500: #71717a;
$borderColor-zinc-600: #52525b;
$borderColor-zinc-700: #3f3f46;
$borderColor-zinc-800: #27272a;
$borderColor-zinc-900: #18181b;
$borderColor-red-50: #fef2f2;
$borderColor-red-100: #fee2e2;
$borderColor-red-200: #fecaca;
$borderColor-red-300: #fca5a5;
$borderColor-red-400: #f87171;
$borderColor-red-500: #ef4444;
$borderColor-red-600: #dc2626;
$borderColor-red-700: #b91c1c;
$borderColor-red-800: #991b1b;
$borderColor-red-900: #7f1d1d;
$borderColor-yellow-50: #fffbeb;
$borderColor-yellow-100: #fef3c7;
$borderColor-yellow-200: #fde68a;
$borderColor-yellow-300: #fcd34d;
$borderColor-yellow-400: #fbbf24;
$borderColor-yellow-500: #f59e0b;
$borderColor-yellow-600: #d97706;
$borderColor-yellow-700: #b45309;
$borderColor-yellow-800: #92400e;
$borderColor-yellow-900: #78350f;
$borderColor-green-50: #f0fdf4;
$borderColor-green-100: #dcfce7;
$borderColor-green-200: #bbf7d0;
$borderColor-green-300: #86efac;
$borderColor-green-400: #4ade80;
$borderColor-green-500: #22c55e;
$borderColor-green-600: #16a34a;
$borderColor-green-700: #15803d;
$borderColor-green-800: #166534;
$borderColor-green-900: #14532d;
$borderColor-blue-100: #F0F5FF;
$borderColor-blue-200: #D6E4FF;
$borderColor-blue-300: #ADC6FF;
$borderColor-blue-400: #85A5FF;
$borderColor-blue-500: #597EF7;
$borderColor-blue-600: #2F54EB;
$borderColor-blue-700: #1D39C4;
$borderColor-blue-800: #10239E;
$borderColor-blue-900: #061178;
$borderColor-indigo-50: #eef2ff;
$borderColor-indigo-100: #e0e7ff;
$borderColor-indigo-200: #c7d2fe;
$borderColor-indigo-300: #a5b4fc;
$borderColor-indigo-400: #818cf8;
$borderColor-indigo-500: #6366f1;
$borderColor-indigo-600: #4f46e5;
$borderColor-indigo-700: #4338ca;
$borderColor-indigo-800: #3730a3;
$borderColor-indigo-900: #312e81;
$borderColor-purple-50: #f5f3ff;
$borderColor-purple-100: #ede9fe;
$borderColor-purple-200: #ddd6fe;
$borderColor-purple-300: #c4b5fd;
$borderColor-purple-400: #a78bfa;
$borderColor-purple-500: #8b5cf6;
$borderColor-purple-600: #7c3aed;
$borderColor-purple-700: #6d28d9;
$borderColor-purple-800: #5b21b6;
$borderColor-purple-900: #4c1d95;
$borderColor-pink-50: #fdf2f8;
$borderColor-pink-100: #fce7f3;
$borderColor-pink-200: #fbcfe8;
$borderColor-pink-300: #f9a8d4;
$borderColor-pink-400: #f472b6;
$borderColor-pink-500: #ec4899;
$borderColor-pink-600: #db2777;
$borderColor-pink-700: #be185d;
$borderColor-pink-800: #9d174d;
$borderColor-pink-900: #831843;
$borderColor-emerald-50: #ecfdf5;
$borderColor-emerald-100: #d1fae5;
$borderColor-emerald-200: #a7f3d0;
$borderColor-emerald-300: #6ee7b7;
$borderColor-emerald-400: #34d399;
$borderColor-emerald-500: #10b981;
$borderColor-emerald-600: #059669;
$borderColor-emerald-700: #047857;
$borderColor-emerald-800: #065f46;
$borderColor-emerald-900: #064e3b;
$borderColor-DEFAULT: #e5e7eb;

$borderOpacity-0: 0;
$borderOpacity-5: 0.05;
$borderOpacity-10: 0.1;
$borderOpacity-20: 0.2;
$borderOpacity-25: 0.25;
$borderOpacity-30: 0.3;
$borderOpacity-40: 0.4;
$borderOpacity-50: 0.5;
$borderOpacity-60: 0.6;
$borderOpacity-70: 0.7;
$borderOpacity-75: 0.75;
$borderOpacity-80: 0.8;
$borderOpacity-90: 0.9;
$borderOpacity-95: 0.95;
$borderOpacity-100: 1;

$borderRadius-none: 0px;
$borderRadius-sm: 0.125rem;
$borderRadius-DEFAULT: 0.25rem;
$borderRadius-md: 0.375rem;
$borderRadius-lg: 0.5rem;
$borderRadius-xl: 0.75rem;
$borderRadius-2xl: 1rem;
$borderRadius-3xl: 1.5rem;
$borderRadius-full: 9999px;

$borderWidth-0: 0px;
$borderWidth-2: 2px;
$borderWidth-4: 4px;
$borderWidth-8: 8px;
$borderWidth-DEFAULT: 1px;

$boxShadow-sm: (0 1px 2px 0 rgba(0, 0, 0, 0.05));
$boxShadow-DEFAULT: (0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1));
$boxShadow-md: (0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1));
$boxShadow-lg: (0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1));
$boxShadow-xl: (0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1));
$boxShadow-2xl: (0 25px 50px -12px rgba(0, 0, 0, 0.25));
$boxShadow-inner: (inset 0 2px 4px 0 rgba(0, 0, 0, 0.05));
$boxShadow-none: none;

$boxShadowColor-transparent: transparent;
$boxShadowColor-current: currentColor;
$boxShadowColor-error-ultralight: #FEF2F2;
$boxShadowColor-error-light: #FEE2E2;
$boxShadowColor-error-medium: #C82929;
$boxShadowColor-error-dark: #991B1B;
$boxShadowColor-error-ultradark: #7F1D1D;
$boxShadowColor-success-light: #DCFCE7;
$boxShadowColor-success-medium: #15803D;
$boxShadowColor-success-dark: #14532D;
$boxShadowColor-warning-light: #FEF3C7;
$boxShadowColor-warning-medium: #D97706;
$boxShadowColor-warning-dark: #92400E;
$boxShadowColor-black: #000;
$boxShadowColor-white: #fff;
$boxShadowColor-gray-50: #f9fafb;
$boxShadowColor-gray-100: #f3f4f6;
$boxShadowColor-gray-200: #e5e7eb;
$boxShadowColor-gray-300: #d1d5db;
$boxShadowColor-gray-400: #9ca3af;
$boxShadowColor-gray-500: #6b7280;
$boxShadowColor-gray-600: #4b5563;
$boxShadowColor-gray-700: #374151;
$boxShadowColor-gray-800: #1f2937;
$boxShadowColor-gray-900: #111827;
$boxShadowColor-zinc-50: #fafafa;
$boxShadowColor-zinc-100: #f4f4f5;
$boxShadowColor-zinc-200: #e4e4e7;
$boxShadowColor-zinc-300: #d4d4d8;
$boxShadowColor-zinc-400: #a1a1aa;
$boxShadowColor-zinc-500: #71717a;
$boxShadowColor-zinc-600: #52525b;
$boxShadowColor-zinc-700: #3f3f46;
$boxShadowColor-zinc-800: #27272a;
$boxShadowColor-zinc-900: #18181b;
$boxShadowColor-red-50: #fef2f2;
$boxShadowColor-red-100: #fee2e2;
$boxShadowColor-red-200: #fecaca;
$boxShadowColor-red-300: #fca5a5;
$boxShadowColor-red-400: #f87171;
$boxShadowColor-red-500: #ef4444;
$boxShadowColor-red-600: #dc2626;
$boxShadowColor-red-700: #b91c1c;
$boxShadowColor-red-800: #991b1b;
$boxShadowColor-red-900: #7f1d1d;
$boxShadowColor-yellow-50: #fffbeb;
$boxShadowColor-yellow-100: #fef3c7;
$boxShadowColor-yellow-200: #fde68a;
$boxShadowColor-yellow-300: #fcd34d;
$boxShadowColor-yellow-400: #fbbf24;
$boxShadowColor-yellow-500: #f59e0b;
$boxShadowColor-yellow-600: #d97706;
$boxShadowColor-yellow-700: #b45309;
$boxShadowColor-yellow-800: #92400e;
$boxShadowColor-yellow-900: #78350f;
$boxShadowColor-green-50: #f0fdf4;
$boxShadowColor-green-100: #dcfce7;
$boxShadowColor-green-200: #bbf7d0;
$boxShadowColor-green-300: #86efac;
$boxShadowColor-green-400: #4ade80;
$boxShadowColor-green-500: #22c55e;
$boxShadowColor-green-600: #16a34a;
$boxShadowColor-green-700: #15803d;
$boxShadowColor-green-800: #166534;
$boxShadowColor-green-900: #14532d;
$boxShadowColor-blue-100: #F0F5FF;
$boxShadowColor-blue-200: #D6E4FF;
$boxShadowColor-blue-300: #ADC6FF;
$boxShadowColor-blue-400: #85A5FF;
$boxShadowColor-blue-500: #597EF7;
$boxShadowColor-blue-600: #2F54EB;
$boxShadowColor-blue-700: #1D39C4;
$boxShadowColor-blue-800: #10239E;
$boxShadowColor-blue-900: #061178;
$boxShadowColor-indigo-50: #eef2ff;
$boxShadowColor-indigo-100: #e0e7ff;
$boxShadowColor-indigo-200: #c7d2fe;
$boxShadowColor-indigo-300: #a5b4fc;
$boxShadowColor-indigo-400: #818cf8;
$boxShadowColor-indigo-500: #6366f1;
$boxShadowColor-indigo-600: #4f46e5;
$boxShadowColor-indigo-700: #4338ca;
$boxShadowColor-indigo-800: #3730a3;
$boxShadowColor-indigo-900: #312e81;
$boxShadowColor-purple-50: #f5f3ff;
$boxShadowColor-purple-100: #ede9fe;
$boxShadowColor-purple-200: #ddd6fe;
$boxShadowColor-purple-300: #c4b5fd;
$boxShadowColor-purple-400: #a78bfa;
$boxShadowColor-purple-500: #8b5cf6;
$boxShadowColor-purple-600: #7c3aed;
$boxShadowColor-purple-700: #6d28d9;
$boxShadowColor-purple-800: #5b21b6;
$boxShadowColor-purple-900: #4c1d95;
$boxShadowColor-pink-50: #fdf2f8;
$boxShadowColor-pink-100: #fce7f3;
$boxShadowColor-pink-200: #fbcfe8;
$boxShadowColor-pink-300: #f9a8d4;
$boxShadowColor-pink-400: #f472b6;
$boxShadowColor-pink-500: #ec4899;
$boxShadowColor-pink-600: #db2777;
$boxShadowColor-pink-700: #be185d;
$boxShadowColor-pink-800: #9d174d;
$boxShadowColor-pink-900: #831843;
$boxShadowColor-emerald-50: #ecfdf5;
$boxShadowColor-emerald-100: #d1fae5;
$boxShadowColor-emerald-200: #a7f3d0;
$boxShadowColor-emerald-300: #6ee7b7;
$boxShadowColor-emerald-400: #34d399;
$boxShadowColor-emerald-500: #10b981;
$boxShadowColor-emerald-600: #059669;
$boxShadowColor-emerald-700: #047857;
$boxShadowColor-emerald-800: #065f46;
$boxShadowColor-emerald-900: #064e3b;

$caretColor-transparent: transparent;
$caretColor-current: currentColor;
$caretColor-error-ultralight: #FEF2F2;
$caretColor-error-light: #FEE2E2;
$caretColor-error-medium: #C82929;
$caretColor-error-dark: #991B1B;
$caretColor-error-ultradark: #7F1D1D;
$caretColor-success-light: #DCFCE7;
$caretColor-success-medium: #15803D;
$caretColor-success-dark: #14532D;
$caretColor-warning-light: #FEF3C7;
$caretColor-warning-medium: #D97706;
$caretColor-warning-dark: #92400E;
$caretColor-black: #000;
$caretColor-white: #fff;
$caretColor-gray-50: #f9fafb;
$caretColor-gray-100: #f3f4f6;
$caretColor-gray-200: #e5e7eb;
$caretColor-gray-300: #d1d5db;
$caretColor-gray-400: #9ca3af;
$caretColor-gray-500: #6b7280;
$caretColor-gray-600: #4b5563;
$caretColor-gray-700: #374151;
$caretColor-gray-800: #1f2937;
$caretColor-gray-900: #111827;
$caretColor-zinc-50: #fafafa;
$caretColor-zinc-100: #f4f4f5;
$caretColor-zinc-200: #e4e4e7;
$caretColor-zinc-300: #d4d4d8;
$caretColor-zinc-400: #a1a1aa;
$caretColor-zinc-500: #71717a;
$caretColor-zinc-600: #52525b;
$caretColor-zinc-700: #3f3f46;
$caretColor-zinc-800: #27272a;
$caretColor-zinc-900: #18181b;
$caretColor-red-50: #fef2f2;
$caretColor-red-100: #fee2e2;
$caretColor-red-200: #fecaca;
$caretColor-red-300: #fca5a5;
$caretColor-red-400: #f87171;
$caretColor-red-500: #ef4444;
$caretColor-red-600: #dc2626;
$caretColor-red-700: #b91c1c;
$caretColor-red-800: #991b1b;
$caretColor-red-900: #7f1d1d;
$caretColor-yellow-50: #fffbeb;
$caretColor-yellow-100: #fef3c7;
$caretColor-yellow-200: #fde68a;
$caretColor-yellow-300: #fcd34d;
$caretColor-yellow-400: #fbbf24;
$caretColor-yellow-500: #f59e0b;
$caretColor-yellow-600: #d97706;
$caretColor-yellow-700: #b45309;
$caretColor-yellow-800: #92400e;
$caretColor-yellow-900: #78350f;
$caretColor-green-50: #f0fdf4;
$caretColor-green-100: #dcfce7;
$caretColor-green-200: #bbf7d0;
$caretColor-green-300: #86efac;
$caretColor-green-400: #4ade80;
$caretColor-green-500: #22c55e;
$caretColor-green-600: #16a34a;
$caretColor-green-700: #15803d;
$caretColor-green-800: #166534;
$caretColor-green-900: #14532d;
$caretColor-blue-100: #F0F5FF;
$caretColor-blue-200: #D6E4FF;
$caretColor-blue-300: #ADC6FF;
$caretColor-blue-400: #85A5FF;
$caretColor-blue-500: #597EF7;
$caretColor-blue-600: #2F54EB;
$caretColor-blue-700: #1D39C4;
$caretColor-blue-800: #10239E;
$caretColor-blue-900: #061178;
$caretColor-indigo-50: #eef2ff;
$caretColor-indigo-100: #e0e7ff;
$caretColor-indigo-200: #c7d2fe;
$caretColor-indigo-300: #a5b4fc;
$caretColor-indigo-400: #818cf8;
$caretColor-indigo-500: #6366f1;
$caretColor-indigo-600: #4f46e5;
$caretColor-indigo-700: #4338ca;
$caretColor-indigo-800: #3730a3;
$caretColor-indigo-900: #312e81;
$caretColor-purple-50: #f5f3ff;
$caretColor-purple-100: #ede9fe;
$caretColor-purple-200: #ddd6fe;
$caretColor-purple-300: #c4b5fd;
$caretColor-purple-400: #a78bfa;
$caretColor-purple-500: #8b5cf6;
$caretColor-purple-600: #7c3aed;
$caretColor-purple-700: #6d28d9;
$caretColor-purple-800: #5b21b6;
$caretColor-purple-900: #4c1d95;
$caretColor-pink-50: #fdf2f8;
$caretColor-pink-100: #fce7f3;
$caretColor-pink-200: #fbcfe8;
$caretColor-pink-300: #f9a8d4;
$caretColor-pink-400: #f472b6;
$caretColor-pink-500: #ec4899;
$caretColor-pink-600: #db2777;
$caretColor-pink-700: #be185d;
$caretColor-pink-800: #9d174d;
$caretColor-pink-900: #831843;
$caretColor-emerald-50: #ecfdf5;
$caretColor-emerald-100: #d1fae5;
$caretColor-emerald-200: #a7f3d0;
$caretColor-emerald-300: #6ee7b7;
$caretColor-emerald-400: #34d399;
$caretColor-emerald-500: #10b981;
$caretColor-emerald-600: #059669;
$caretColor-emerald-700: #047857;
$caretColor-emerald-800: #065f46;
$caretColor-emerald-900: #064e3b;

$accentColor-transparent: transparent;
$accentColor-current: currentColor;
$accentColor-error-ultralight: #FEF2F2;
$accentColor-error-light: #FEE2E2;
$accentColor-error-medium: #C82929;
$accentColor-error-dark: #991B1B;
$accentColor-error-ultradark: #7F1D1D;
$accentColor-success-light: #DCFCE7;
$accentColor-success-medium: #15803D;
$accentColor-success-dark: #14532D;
$accentColor-warning-light: #FEF3C7;
$accentColor-warning-medium: #D97706;
$accentColor-warning-dark: #92400E;
$accentColor-black: #000;
$accentColor-white: #fff;
$accentColor-gray-50: #f9fafb;
$accentColor-gray-100: #f3f4f6;
$accentColor-gray-200: #e5e7eb;
$accentColor-gray-300: #d1d5db;
$accentColor-gray-400: #9ca3af;
$accentColor-gray-500: #6b7280;
$accentColor-gray-600: #4b5563;
$accentColor-gray-700: #374151;
$accentColor-gray-800: #1f2937;
$accentColor-gray-900: #111827;
$accentColor-zinc-50: #fafafa;
$accentColor-zinc-100: #f4f4f5;
$accentColor-zinc-200: #e4e4e7;
$accentColor-zinc-300: #d4d4d8;
$accentColor-zinc-400: #a1a1aa;
$accentColor-zinc-500: #71717a;
$accentColor-zinc-600: #52525b;
$accentColor-zinc-700: #3f3f46;
$accentColor-zinc-800: #27272a;
$accentColor-zinc-900: #18181b;
$accentColor-red-50: #fef2f2;
$accentColor-red-100: #fee2e2;
$accentColor-red-200: #fecaca;
$accentColor-red-300: #fca5a5;
$accentColor-red-400: #f87171;
$accentColor-red-500: #ef4444;
$accentColor-red-600: #dc2626;
$accentColor-red-700: #b91c1c;
$accentColor-red-800: #991b1b;
$accentColor-red-900: #7f1d1d;
$accentColor-yellow-50: #fffbeb;
$accentColor-yellow-100: #fef3c7;
$accentColor-yellow-200: #fde68a;
$accentColor-yellow-300: #fcd34d;
$accentColor-yellow-400: #fbbf24;
$accentColor-yellow-500: #f59e0b;
$accentColor-yellow-600: #d97706;
$accentColor-yellow-700: #b45309;
$accentColor-yellow-800: #92400e;
$accentColor-yellow-900: #78350f;
$accentColor-green-50: #f0fdf4;
$accentColor-green-100: #dcfce7;
$accentColor-green-200: #bbf7d0;
$accentColor-green-300: #86efac;
$accentColor-green-400: #4ade80;
$accentColor-green-500: #22c55e;
$accentColor-green-600: #16a34a;
$accentColor-green-700: #15803d;
$accentColor-green-800: #166534;
$accentColor-green-900: #14532d;
$accentColor-blue-100: #F0F5FF;
$accentColor-blue-200: #D6E4FF;
$accentColor-blue-300: #ADC6FF;
$accentColor-blue-400: #85A5FF;
$accentColor-blue-500: #597EF7;
$accentColor-blue-600: #2F54EB;
$accentColor-blue-700: #1D39C4;
$accentColor-blue-800: #10239E;
$accentColor-blue-900: #061178;
$accentColor-indigo-50: #eef2ff;
$accentColor-indigo-100: #e0e7ff;
$accentColor-indigo-200: #c7d2fe;
$accentColor-indigo-300: #a5b4fc;
$accentColor-indigo-400: #818cf8;
$accentColor-indigo-500: #6366f1;
$accentColor-indigo-600: #4f46e5;
$accentColor-indigo-700: #4338ca;
$accentColor-indigo-800: #3730a3;
$accentColor-indigo-900: #312e81;
$accentColor-purple-50: #f5f3ff;
$accentColor-purple-100: #ede9fe;
$accentColor-purple-200: #ddd6fe;
$accentColor-purple-300: #c4b5fd;
$accentColor-purple-400: #a78bfa;
$accentColor-purple-500: #8b5cf6;
$accentColor-purple-600: #7c3aed;
$accentColor-purple-700: #6d28d9;
$accentColor-purple-800: #5b21b6;
$accentColor-purple-900: #4c1d95;
$accentColor-pink-50: #fdf2f8;
$accentColor-pink-100: #fce7f3;
$accentColor-pink-200: #fbcfe8;
$accentColor-pink-300: #f9a8d4;
$accentColor-pink-400: #f472b6;
$accentColor-pink-500: #ec4899;
$accentColor-pink-600: #db2777;
$accentColor-pink-700: #be185d;
$accentColor-pink-800: #9d174d;
$accentColor-pink-900: #831843;
$accentColor-emerald-50: #ecfdf5;
$accentColor-emerald-100: #d1fae5;
$accentColor-emerald-200: #a7f3d0;
$accentColor-emerald-300: #6ee7b7;
$accentColor-emerald-400: #34d399;
$accentColor-emerald-500: #10b981;
$accentColor-emerald-600: #059669;
$accentColor-emerald-700: #047857;
$accentColor-emerald-800: #065f46;
$accentColor-emerald-900: #064e3b;
$accentColor-auto: auto;

$contrast-0: 0;
$contrast-50: .5;
$contrast-75: .75;
$contrast-100: 1;
$contrast-125: 1.25;
$contrast-150: 1.5;
$contrast-200: 2;


$content-none: none;

$cursor-auto: auto;
$cursor-default: default;
$cursor-pointer: pointer;
$cursor-wait: wait;
$cursor-text: text;
$cursor-move: move;
$cursor-help: help;
$cursor-not-allowed: not-allowed;
$cursor-none: none;
$cursor-context-menu: context-menu;
$cursor-progress: progress;
$cursor-cell: cell;
$cursor-crosshair: crosshair;
$cursor-vertical-text: vertical-text;
$cursor-alias: alias;
$cursor-copy: copy;
$cursor-no-drop: no-drop;
$cursor-grab: grab;
$cursor-grabbing: grabbing;
$cursor-all-scroll: all-scroll;
$cursor-col-resize: col-resize;
$cursor-row-resize: row-resize;
$cursor-n-resize: n-resize;
$cursor-e-resize: e-resize;
$cursor-s-resize: s-resize;
$cursor-w-resize: w-resize;
$cursor-ne-resize: ne-resize;
$cursor-nw-resize: nw-resize;
$cursor-se-resize: se-resize;
$cursor-sw-resize: sw-resize;
$cursor-ew-resize: ew-resize;
$cursor-ns-resize: ns-resize;
$cursor-nesw-resize: nesw-resize;
$cursor-nwse-resize: nwse-resize;
$cursor-zoom-in: zoom-in;
$cursor-zoom-out: zoom-out;

$divideColor-transparent: transparent;
$divideColor-current: currentColor;
$divideColor-error-ultralight: #FEF2F2;
$divideColor-error-light: #FEE2E2;
$divideColor-error-medium: #C82929;
$divideColor-error-dark: #991B1B;
$divideColor-error-ultradark: #7F1D1D;
$divideColor-success-light: #DCFCE7;
$divideColor-success-medium: #15803D;
$divideColor-success-dark: #14532D;
$divideColor-warning-light: #FEF3C7;
$divideColor-warning-medium: #D97706;
$divideColor-warning-dark: #92400E;
$divideColor-black: #000;
$divideColor-white: #fff;
$divideColor-gray-50: #f9fafb;
$divideColor-gray-100: #f3f4f6;
$divideColor-gray-200: #e5e7eb;
$divideColor-gray-300: #d1d5db;
$divideColor-gray-400: #9ca3af;
$divideColor-gray-500: #6b7280;
$divideColor-gray-600: #4b5563;
$divideColor-gray-700: #374151;
$divideColor-gray-800: #1f2937;
$divideColor-gray-900: #111827;
$divideColor-zinc-50: #fafafa;
$divideColor-zinc-100: #f4f4f5;
$divideColor-zinc-200: #e4e4e7;
$divideColor-zinc-300: #d4d4d8;
$divideColor-zinc-400: #a1a1aa;
$divideColor-zinc-500: #71717a;
$divideColor-zinc-600: #52525b;
$divideColor-zinc-700: #3f3f46;
$divideColor-zinc-800: #27272a;
$divideColor-zinc-900: #18181b;
$divideColor-red-50: #fef2f2;
$divideColor-red-100: #fee2e2;
$divideColor-red-200: #fecaca;
$divideColor-red-300: #fca5a5;
$divideColor-red-400: #f87171;
$divideColor-red-500: #ef4444;
$divideColor-red-600: #dc2626;
$divideColor-red-700: #b91c1c;
$divideColor-red-800: #991b1b;
$divideColor-red-900: #7f1d1d;
$divideColor-yellow-50: #fffbeb;
$divideColor-yellow-100: #fef3c7;
$divideColor-yellow-200: #fde68a;
$divideColor-yellow-300: #fcd34d;
$divideColor-yellow-400: #fbbf24;
$divideColor-yellow-500: #f59e0b;
$divideColor-yellow-600: #d97706;
$divideColor-yellow-700: #b45309;
$divideColor-yellow-800: #92400e;
$divideColor-yellow-900: #78350f;
$divideColor-green-50: #f0fdf4;
$divideColor-green-100: #dcfce7;
$divideColor-green-200: #bbf7d0;
$divideColor-green-300: #86efac;
$divideColor-green-400: #4ade80;
$divideColor-green-500: #22c55e;
$divideColor-green-600: #16a34a;
$divideColor-green-700: #15803d;
$divideColor-green-800: #166534;
$divideColor-green-900: #14532d;
$divideColor-blue-100: #F0F5FF;
$divideColor-blue-200: #D6E4FF;
$divideColor-blue-300: #ADC6FF;
$divideColor-blue-400: #85A5FF;
$divideColor-blue-500: #597EF7;
$divideColor-blue-600: #2F54EB;
$divideColor-blue-700: #1D39C4;
$divideColor-blue-800: #10239E;
$divideColor-blue-900: #061178;
$divideColor-indigo-50: #eef2ff;
$divideColor-indigo-100: #e0e7ff;
$divideColor-indigo-200: #c7d2fe;
$divideColor-indigo-300: #a5b4fc;
$divideColor-indigo-400: #818cf8;
$divideColor-indigo-500: #6366f1;
$divideColor-indigo-600: #4f46e5;
$divideColor-indigo-700: #4338ca;
$divideColor-indigo-800: #3730a3;
$divideColor-indigo-900: #312e81;
$divideColor-purple-50: #f5f3ff;
$divideColor-purple-100: #ede9fe;
$divideColor-purple-200: #ddd6fe;
$divideColor-purple-300: #c4b5fd;
$divideColor-purple-400: #a78bfa;
$divideColor-purple-500: #8b5cf6;
$divideColor-purple-600: #7c3aed;
$divideColor-purple-700: #6d28d9;
$divideColor-purple-800: #5b21b6;
$divideColor-purple-900: #4c1d95;
$divideColor-pink-50: #fdf2f8;
$divideColor-pink-100: #fce7f3;
$divideColor-pink-200: #fbcfe8;
$divideColor-pink-300: #f9a8d4;
$divideColor-pink-400: #f472b6;
$divideColor-pink-500: #ec4899;
$divideColor-pink-600: #db2777;
$divideColor-pink-700: #be185d;
$divideColor-pink-800: #9d174d;
$divideColor-pink-900: #831843;
$divideColor-emerald-50: #ecfdf5;
$divideColor-emerald-100: #d1fae5;
$divideColor-emerald-200: #a7f3d0;
$divideColor-emerald-300: #6ee7b7;
$divideColor-emerald-400: #34d399;
$divideColor-emerald-500: #10b981;
$divideColor-emerald-600: #059669;
$divideColor-emerald-700: #047857;
$divideColor-emerald-800: #065f46;
$divideColor-emerald-900: #064e3b;
$divideColor-DEFAULT: #e5e7eb;

$divideOpacity-0: 0;
$divideOpacity-5: 0.05;
$divideOpacity-10: 0.1;
$divideOpacity-20: 0.2;
$divideOpacity-25: 0.25;
$divideOpacity-30: 0.3;
$divideOpacity-40: 0.4;
$divideOpacity-50: 0.5;
$divideOpacity-60: 0.6;
$divideOpacity-70: 0.7;
$divideOpacity-75: 0.75;
$divideOpacity-80: 0.8;
$divideOpacity-90: 0.9;
$divideOpacity-95: 0.95;
$divideOpacity-100: 1;

$divideWidth-0: 0px;
$divideWidth-2: 2px;
$divideWidth-4: 4px;
$divideWidth-8: 8px;
$divideWidth-DEFAULT: 1px;

$dropShadow-sm: (0 1px 1px rgba(0, 0, 0, 0.05));
$dropShadow-DEFAULT: (0 1px 2px rgba(0, 0, 0, 0.1),0 1px 1px rgba(0, 0, 0, 0.06));
$dropShadow-md: (0 4px 3px rgba(0, 0, 0, 0.07),0 2px 2px rgba(0, 0, 0, 0.06));
$dropShadow-lg: (0 10px 8px rgba(0, 0, 0, 0.04),0 4px 3px rgba(0, 0, 0, 0.1));
$dropShadow-xl: (0 20px 13px rgba(0, 0, 0, 0.03),0 8px 5px rgba(0, 0, 0, 0.08));
$dropShadow-2xl: (0 25px 25px rgba(0, 0, 0, 0.15));
$dropShadow-none: 0 0 #0000;

$fill-transparent: transparent;
$fill-current: currentColor;
$fill-error-ultralight: #FEF2F2;
$fill-error-light: #FEE2E2;
$fill-error-medium: #C82929;
$fill-error-dark: #991B1B;
$fill-error-ultradark: #7F1D1D;
$fill-success-light: #DCFCE7;
$fill-success-medium: #15803D;
$fill-success-dark: #14532D;
$fill-warning-light: #FEF3C7;
$fill-warning-medium: #D97706;
$fill-warning-dark: #92400E;
$fill-black: #000;
$fill-white: #fff;
$fill-gray-50: #f9fafb;
$fill-gray-100: #f3f4f6;
$fill-gray-200: #e5e7eb;
$fill-gray-300: #d1d5db;
$fill-gray-400: #9ca3af;
$fill-gray-500: #6b7280;
$fill-gray-600: #4b5563;
$fill-gray-700: #374151;
$fill-gray-800: #1f2937;
$fill-gray-900: #111827;
$fill-zinc-50: #fafafa;
$fill-zinc-100: #f4f4f5;
$fill-zinc-200: #e4e4e7;
$fill-zinc-300: #d4d4d8;
$fill-zinc-400: #a1a1aa;
$fill-zinc-500: #71717a;
$fill-zinc-600: #52525b;
$fill-zinc-700: #3f3f46;
$fill-zinc-800: #27272a;
$fill-zinc-900: #18181b;
$fill-red-50: #fef2f2;
$fill-red-100: #fee2e2;
$fill-red-200: #fecaca;
$fill-red-300: #fca5a5;
$fill-red-400: #f87171;
$fill-red-500: #ef4444;
$fill-red-600: #dc2626;
$fill-red-700: #b91c1c;
$fill-red-800: #991b1b;
$fill-red-900: #7f1d1d;
$fill-yellow-50: #fffbeb;
$fill-yellow-100: #fef3c7;
$fill-yellow-200: #fde68a;
$fill-yellow-300: #fcd34d;
$fill-yellow-400: #fbbf24;
$fill-yellow-500: #f59e0b;
$fill-yellow-600: #d97706;
$fill-yellow-700: #b45309;
$fill-yellow-800: #92400e;
$fill-yellow-900: #78350f;
$fill-green-50: #f0fdf4;
$fill-green-100: #dcfce7;
$fill-green-200: #bbf7d0;
$fill-green-300: #86efac;
$fill-green-400: #4ade80;
$fill-green-500: #22c55e;
$fill-green-600: #16a34a;
$fill-green-700: #15803d;
$fill-green-800: #166534;
$fill-green-900: #14532d;
$fill-blue-100: #F0F5FF;
$fill-blue-200: #D6E4FF;
$fill-blue-300: #ADC6FF;
$fill-blue-400: #85A5FF;
$fill-blue-500: #597EF7;
$fill-blue-600: #2F54EB;
$fill-blue-700: #1D39C4;
$fill-blue-800: #10239E;
$fill-blue-900: #061178;
$fill-indigo-50: #eef2ff;
$fill-indigo-100: #e0e7ff;
$fill-indigo-200: #c7d2fe;
$fill-indigo-300: #a5b4fc;
$fill-indigo-400: #818cf8;
$fill-indigo-500: #6366f1;
$fill-indigo-600: #4f46e5;
$fill-indigo-700: #4338ca;
$fill-indigo-800: #3730a3;
$fill-indigo-900: #312e81;
$fill-purple-50: #f5f3ff;
$fill-purple-100: #ede9fe;
$fill-purple-200: #ddd6fe;
$fill-purple-300: #c4b5fd;
$fill-purple-400: #a78bfa;
$fill-purple-500: #8b5cf6;
$fill-purple-600: #7c3aed;
$fill-purple-700: #6d28d9;
$fill-purple-800: #5b21b6;
$fill-purple-900: #4c1d95;
$fill-pink-50: #fdf2f8;
$fill-pink-100: #fce7f3;
$fill-pink-200: #fbcfe8;
$fill-pink-300: #f9a8d4;
$fill-pink-400: #f472b6;
$fill-pink-500: #ec4899;
$fill-pink-600: #db2777;
$fill-pink-700: #be185d;
$fill-pink-800: #9d174d;
$fill-pink-900: #831843;
$fill-emerald-50: #ecfdf5;
$fill-emerald-100: #d1fae5;
$fill-emerald-200: #a7f3d0;
$fill-emerald-300: #6ee7b7;
$fill-emerald-400: #34d399;
$fill-emerald-500: #10b981;
$fill-emerald-600: #059669;
$fill-emerald-700: #047857;
$fill-emerald-800: #065f46;
$fill-emerald-900: #064e3b;

$grayscale-0: 0;
$grayscale-DEFAULT: 100%;

$hueRotate-0: 0deg;
$hueRotate-15: 15deg;
$hueRotate-30: 30deg;
$hueRotate-60: 60deg;
$hueRotate-90: 90deg;
$hueRotate-180: 180deg;

$invert-0: 0;
$invert-DEFAULT: 100%;

$flex-1: 1 1 0%;
$flex-auto: 1 1 auto;
$flex-initial: 0 1 auto;
$flex-none: none;

$flexBasis-0: 0px;
$flexBasis-1: 0.25rem;
$flexBasis-2: 0.5rem;
$flexBasis-3: 0.75rem;
$flexBasis-4: 1rem;
$flexBasis-5: 1.25rem;
$flexBasis-6: 1.5rem;
$flexBasis-7: 1.75rem;
$flexBasis-8: 2rem;
$flexBasis-9: 2.25rem;
$flexBasis-10: 2.5rem;
$flexBasis-11: 2.75rem;
$flexBasis-12: 3rem;
$flexBasis-14: 3.5rem;
$flexBasis-16: 4rem;
$flexBasis-20: 5rem;
$flexBasis-24: 6rem;
$flexBasis-28: 7rem;
$flexBasis-32: 8rem;
$flexBasis-36: 9rem;
$flexBasis-40: 10rem;
$flexBasis-44: 11rem;
$flexBasis-48: 12rem;
$flexBasis-52: 13rem;
$flexBasis-56: 14rem;
$flexBasis-60: 15rem;
$flexBasis-64: 16rem;
$flexBasis-72: 18rem;
$flexBasis-80: 20rem;
$flexBasis-96: 24rem;
$flexBasis-auto: auto;
$flexBasis-px: 1px;
$flexBasis-0-5: 0.125rem;
$flexBasis-1-5: 0.375rem;
$flexBasis-2-5: 0.625rem;
$flexBasis-3-5: 0.875rem;
$flexBasis-1\/2: 50%;
$flexBasis-1\/3: 33.333333%;
$flexBasis-2\/3: 66.666667%;
$flexBasis-1\/4: 25%;
$flexBasis-2\/4: 50%;
$flexBasis-3\/4: 75%;
$flexBasis-1\/5: 20%;
$flexBasis-2\/5: 40%;
$flexBasis-3\/5: 60%;
$flexBasis-4\/5: 80%;
$flexBasis-1\/6: 16.666667%;
$flexBasis-2\/6: 33.333333%;
$flexBasis-3\/6: 50%;
$flexBasis-4\/6: 66.666667%;
$flexBasis-5\/6: 83.333333%;
$flexBasis-1\/12: 8.333333%;
$flexBasis-2\/12: 16.666667%;
$flexBasis-3\/12: 25%;
$flexBasis-4\/12: 33.333333%;
$flexBasis-5\/12: 41.666667%;
$flexBasis-6\/12: 50%;
$flexBasis-7\/12: 58.333333%;
$flexBasis-8\/12: 66.666667%;
$flexBasis-9\/12: 75%;
$flexBasis-10\/12: 83.333333%;
$flexBasis-11\/12: 91.666667%;
$flexBasis-full: 100%;

$flexGrow-0: 0;
$flexGrow-DEFAULT: 1;

$flexShrink-0: 0;
$flexShrink-DEFAULT: 1;

$fontFamily-sans: (ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji","Inter");
$fontFamily-serif: (ui-serif,Georgia,Cambria,"Times New Roman",Times,serif);
$fontFamily-mono: (ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace);

$fontSize-xs: 0.75rem;
$fontSize-xs-lineHeight: 1rem;
$fontSize-sm: 0.875rem;
$fontSize-sm-lineHeight: 1.25rem;
$fontSize-base: 1rem;
$fontSize-base-lineHeight: 1.5rem;
$fontSize-lg: 1.125rem;
$fontSize-lg-lineHeight: 1.75rem;
$fontSize-xl: 1.25rem;
$fontSize-xl-lineHeight: 1.75rem;
$fontSize-2xl: 1.5rem;
$fontSize-2xl-lineHeight: 2rem;
$fontSize-3xl: 1.875rem;
$fontSize-3xl-lineHeight: 2.25rem;
$fontSize-4xl: 2.25rem;
$fontSize-4xl-lineHeight: 2.5rem;
$fontSize-5xl: 3rem;
$fontSize-5xl-lineHeight: 1;
$fontSize-6xl: 3.75rem;
$fontSize-6xl-lineHeight: 1;
$fontSize-7xl: 4.5rem;
$fontSize-7xl-lineHeight: 1;
$fontSize-8xl: 6rem;
$fontSize-8xl-lineHeight: 1;
$fontSize-9xl: 8rem;
$fontSize-9xl-lineHeight: 1;

$fontWeight-thin: 100;
$fontWeight-extralight: 200;
$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;
$fontWeight-extrabold: 800;
$fontWeight-black: 900;

$gap-0: 0px;
$gap-1: 0.25rem;
$gap-2: 0.5rem;
$gap-3: 0.75rem;
$gap-4: 1rem;
$gap-5: 1.25rem;
$gap-6: 1.5rem;
$gap-7: 1.75rem;
$gap-8: 2rem;
$gap-9: 2.25rem;
$gap-10: 2.5rem;
$gap-11: 2.75rem;
$gap-12: 3rem;
$gap-14: 3.5rem;
$gap-16: 4rem;
$gap-20: 5rem;
$gap-24: 6rem;
$gap-28: 7rem;
$gap-32: 8rem;
$gap-36: 9rem;
$gap-40: 10rem;
$gap-44: 11rem;
$gap-48: 12rem;
$gap-52: 13rem;
$gap-56: 14rem;
$gap-60: 15rem;
$gap-64: 16rem;
$gap-72: 18rem;
$gap-80: 20rem;
$gap-96: 24rem;
$gap-px: 1px;
$gap-0-5: 0.125rem;
$gap-1-5: 0.375rem;
$gap-2-5: 0.625rem;
$gap-3-5: 0.875rem;

$gradientColorStops-transparent: transparent;
$gradientColorStops-current: currentColor;
$gradientColorStops-error-ultralight: #FEF2F2;
$gradientColorStops-error-light: #FEE2E2;
$gradientColorStops-error-medium: #C82929;
$gradientColorStops-error-dark: #991B1B;
$gradientColorStops-error-ultradark: #7F1D1D;
$gradientColorStops-success-light: #DCFCE7;
$gradientColorStops-success-medium: #15803D;
$gradientColorStops-success-dark: #14532D;
$gradientColorStops-warning-light: #FEF3C7;
$gradientColorStops-warning-medium: #D97706;
$gradientColorStops-warning-dark: #92400E;
$gradientColorStops-black: #000;
$gradientColorStops-white: #fff;
$gradientColorStops-gray-50: #f9fafb;
$gradientColorStops-gray-100: #f3f4f6;
$gradientColorStops-gray-200: #e5e7eb;
$gradientColorStops-gray-300: #d1d5db;
$gradientColorStops-gray-400: #9ca3af;
$gradientColorStops-gray-500: #6b7280;
$gradientColorStops-gray-600: #4b5563;
$gradientColorStops-gray-700: #374151;
$gradientColorStops-gray-800: #1f2937;
$gradientColorStops-gray-900: #111827;
$gradientColorStops-zinc-50: #fafafa;
$gradientColorStops-zinc-100: #f4f4f5;
$gradientColorStops-zinc-200: #e4e4e7;
$gradientColorStops-zinc-300: #d4d4d8;
$gradientColorStops-zinc-400: #a1a1aa;
$gradientColorStops-zinc-500: #71717a;
$gradientColorStops-zinc-600: #52525b;
$gradientColorStops-zinc-700: #3f3f46;
$gradientColorStops-zinc-800: #27272a;
$gradientColorStops-zinc-900: #18181b;
$gradientColorStops-red-50: #fef2f2;
$gradientColorStops-red-100: #fee2e2;
$gradientColorStops-red-200: #fecaca;
$gradientColorStops-red-300: #fca5a5;
$gradientColorStops-red-400: #f87171;
$gradientColorStops-red-500: #ef4444;
$gradientColorStops-red-600: #dc2626;
$gradientColorStops-red-700: #b91c1c;
$gradientColorStops-red-800: #991b1b;
$gradientColorStops-red-900: #7f1d1d;
$gradientColorStops-yellow-50: #fffbeb;
$gradientColorStops-yellow-100: #fef3c7;
$gradientColorStops-yellow-200: #fde68a;
$gradientColorStops-yellow-300: #fcd34d;
$gradientColorStops-yellow-400: #fbbf24;
$gradientColorStops-yellow-500: #f59e0b;
$gradientColorStops-yellow-600: #d97706;
$gradientColorStops-yellow-700: #b45309;
$gradientColorStops-yellow-800: #92400e;
$gradientColorStops-yellow-900: #78350f;
$gradientColorStops-green-50: #f0fdf4;
$gradientColorStops-green-100: #dcfce7;
$gradientColorStops-green-200: #bbf7d0;
$gradientColorStops-green-300: #86efac;
$gradientColorStops-green-400: #4ade80;
$gradientColorStops-green-500: #22c55e;
$gradientColorStops-green-600: #16a34a;
$gradientColorStops-green-700: #15803d;
$gradientColorStops-green-800: #166534;
$gradientColorStops-green-900: #14532d;
$gradientColorStops-blue-100: #F0F5FF;
$gradientColorStops-blue-200: #D6E4FF;
$gradientColorStops-blue-300: #ADC6FF;
$gradientColorStops-blue-400: #85A5FF;
$gradientColorStops-blue-500: #597EF7;
$gradientColorStops-blue-600: #2F54EB;
$gradientColorStops-blue-700: #1D39C4;
$gradientColorStops-blue-800: #10239E;
$gradientColorStops-blue-900: #061178;
$gradientColorStops-indigo-50: #eef2ff;
$gradientColorStops-indigo-100: #e0e7ff;
$gradientColorStops-indigo-200: #c7d2fe;
$gradientColorStops-indigo-300: #a5b4fc;
$gradientColorStops-indigo-400: #818cf8;
$gradientColorStops-indigo-500: #6366f1;
$gradientColorStops-indigo-600: #4f46e5;
$gradientColorStops-indigo-700: #4338ca;
$gradientColorStops-indigo-800: #3730a3;
$gradientColorStops-indigo-900: #312e81;
$gradientColorStops-purple-50: #f5f3ff;
$gradientColorStops-purple-100: #ede9fe;
$gradientColorStops-purple-200: #ddd6fe;
$gradientColorStops-purple-300: #c4b5fd;
$gradientColorStops-purple-400: #a78bfa;
$gradientColorStops-purple-500: #8b5cf6;
$gradientColorStops-purple-600: #7c3aed;
$gradientColorStops-purple-700: #6d28d9;
$gradientColorStops-purple-800: #5b21b6;
$gradientColorStops-purple-900: #4c1d95;
$gradientColorStops-pink-50: #fdf2f8;
$gradientColorStops-pink-100: #fce7f3;
$gradientColorStops-pink-200: #fbcfe8;
$gradientColorStops-pink-300: #f9a8d4;
$gradientColorStops-pink-400: #f472b6;
$gradientColorStops-pink-500: #ec4899;
$gradientColorStops-pink-600: #db2777;
$gradientColorStops-pink-700: #be185d;
$gradientColorStops-pink-800: #9d174d;
$gradientColorStops-pink-900: #831843;
$gradientColorStops-emerald-50: #ecfdf5;
$gradientColorStops-emerald-100: #d1fae5;
$gradientColorStops-emerald-200: #a7f3d0;
$gradientColorStops-emerald-300: #6ee7b7;
$gradientColorStops-emerald-400: #34d399;
$gradientColorStops-emerald-500: #10b981;
$gradientColorStops-emerald-600: #059669;
$gradientColorStops-emerald-700: #047857;
$gradientColorStops-emerald-800: #065f46;
$gradientColorStops-emerald-900: #064e3b;

$gridAutoColumns-auto: auto;
$gridAutoColumns-min: min-content;
$gridAutoColumns-max: max-content;
$gridAutoColumns-fr: (minmax(0, 1fr));

$gridAutoRows-auto: auto;
$gridAutoRows-min: min-content;
$gridAutoRows-max: max-content;
$gridAutoRows-fr: (minmax(0, 1fr));

$gridColumn-auto: auto;
$gridColumn-span-1: span 1 / span 1;
$gridColumn-span-2: span 2 / span 2;
$gridColumn-span-3: span 3 / span 3;
$gridColumn-span-4: span 4 / span 4;
$gridColumn-span-5: span 5 / span 5;
$gridColumn-span-6: span 6 / span 6;
$gridColumn-span-7: span 7 / span 7;
$gridColumn-span-8: span 8 / span 8;
$gridColumn-span-9: span 9 / span 9;
$gridColumn-span-10: span 10 / span 10;
$gridColumn-span-11: span 11 / span 11;
$gridColumn-span-12: span 12 / span 12;
$gridColumn-span-full: 1 / -1;

$gridColumnEnd-1: 1;
$gridColumnEnd-2: 2;
$gridColumnEnd-3: 3;
$gridColumnEnd-4: 4;
$gridColumnEnd-5: 5;
$gridColumnEnd-6: 6;
$gridColumnEnd-7: 7;
$gridColumnEnd-8: 8;
$gridColumnEnd-9: 9;
$gridColumnEnd-10: 10;
$gridColumnEnd-11: 11;
$gridColumnEnd-12: 12;
$gridColumnEnd-13: 13;
$gridColumnEnd-auto: auto;

$gridColumnStart-1: 1;
$gridColumnStart-2: 2;
$gridColumnStart-3: 3;
$gridColumnStart-4: 4;
$gridColumnStart-5: 5;
$gridColumnStart-6: 6;
$gridColumnStart-7: 7;
$gridColumnStart-8: 8;
$gridColumnStart-9: 9;
$gridColumnStart-10: 10;
$gridColumnStart-11: 11;
$gridColumnStart-12: 12;
$gridColumnStart-13: 13;
$gridColumnStart-auto: auto;

$gridRow-auto: auto;
$gridRow-span-1: span 1 / span 1;
$gridRow-span-2: span 2 / span 2;
$gridRow-span-3: span 3 / span 3;
$gridRow-span-4: span 4 / span 4;
$gridRow-span-5: span 5 / span 5;
$gridRow-span-6: span 6 / span 6;
$gridRow-span-full: 1 / -1;

$gridRowStart-1: 1;
$gridRowStart-2: 2;
$gridRowStart-3: 3;
$gridRowStart-4: 4;
$gridRowStart-5: 5;
$gridRowStart-6: 6;
$gridRowStart-7: 7;
$gridRowStart-auto: auto;

$gridRowEnd-1: 1;
$gridRowEnd-2: 2;
$gridRowEnd-3: 3;
$gridRowEnd-4: 4;
$gridRowEnd-5: 5;
$gridRowEnd-6: 6;
$gridRowEnd-7: 7;
$gridRowEnd-auto: auto;

$gridTemplateColumns-1: (repeat(1, minmax(0, 1fr)));
$gridTemplateColumns-2: (repeat(2, minmax(0, 1fr)));
$gridTemplateColumns-3: (repeat(3, minmax(0, 1fr)));
$gridTemplateColumns-4: (repeat(4, minmax(0, 1fr)));
$gridTemplateColumns-5: (repeat(5, minmax(0, 1fr)));
$gridTemplateColumns-6: (repeat(6, minmax(0, 1fr)));
$gridTemplateColumns-7: (repeat(7, minmax(0, 1fr)));
$gridTemplateColumns-8: (repeat(8, minmax(0, 1fr)));
$gridTemplateColumns-9: (repeat(9, minmax(0, 1fr)));
$gridTemplateColumns-10: (repeat(10, minmax(0, 1fr)));
$gridTemplateColumns-11: (repeat(11, minmax(0, 1fr)));
$gridTemplateColumns-12: (repeat(12, minmax(0, 1fr)));
$gridTemplateColumns-none: none;

$gridTemplateRows-1: (repeat(1, minmax(0, 1fr)));
$gridTemplateRows-2: (repeat(2, minmax(0, 1fr)));
$gridTemplateRows-3: (repeat(3, minmax(0, 1fr)));
$gridTemplateRows-4: (repeat(4, minmax(0, 1fr)));
$gridTemplateRows-5: (repeat(5, minmax(0, 1fr)));
$gridTemplateRows-6: (repeat(6, minmax(0, 1fr)));
$gridTemplateRows-none: none;

$height-0: 0px;
$height-1: 0.25rem;
$height-2: 0.5rem;
$height-3: 0.75rem;
$height-4: 1rem;
$height-5: 1.25rem;
$height-6: 1.5rem;
$height-7: 1.75rem;
$height-8: 2rem;
$height-9: 2.25rem;
$height-10: 2.5rem;
$height-11: 2.75rem;
$height-12: 3rem;
$height-14: 3.5rem;
$height-16: 4rem;
$height-20: 5rem;
$height-24: 6rem;
$height-28: 7rem;
$height-32: 8rem;
$height-36: 9rem;
$height-40: 10rem;
$height-44: 11rem;
$height-48: 12rem;
$height-52: 13rem;
$height-56: 14rem;
$height-60: 15rem;
$height-64: 16rem;
$height-72: 18rem;
$height-80: 20rem;
$height-96: 24rem;
$height-auto: auto;
$height-px: 1px;
$height-0-5: 0.125rem;
$height-1-5: 0.375rem;
$height-2-5: 0.625rem;
$height-3-5: 0.875rem;
$height-1\/2: 50%;
$height-1\/3: 33.333333%;
$height-2\/3: 66.666667%;
$height-1\/4: 25%;
$height-2\/4: 50%;
$height-3\/4: 75%;
$height-1\/5: 20%;
$height-2\/5: 40%;
$height-3\/5: 60%;
$height-4\/5: 80%;
$height-1\/6: 16.666667%;
$height-2\/6: 33.333333%;
$height-3\/6: 50%;
$height-4\/6: 66.666667%;
$height-5\/6: 83.333333%;
$height-full: 100%;
$height-screen: 100vh;
$height-min: min-content;
$height-max: max-content;
$height-fit: fit-content;

$inset-0: 0px;
$inset-1: 0.25rem;
$inset-2: 0.5rem;
$inset-3: 0.75rem;
$inset-4: 1rem;
$inset-5: 1.25rem;
$inset-6: 1.5rem;
$inset-7: 1.75rem;
$inset-8: 2rem;
$inset-9: 2.25rem;
$inset-10: 2.5rem;
$inset-11: 2.75rem;
$inset-12: 3rem;
$inset-14: 3.5rem;
$inset-16: 4rem;
$inset-20: 5rem;
$inset-24: 6rem;
$inset-28: 7rem;
$inset-32: 8rem;
$inset-36: 9rem;
$inset-40: 10rem;
$inset-44: 11rem;
$inset-48: 12rem;
$inset-52: 13rem;
$inset-56: 14rem;
$inset-60: 15rem;
$inset-64: 16rem;
$inset-72: 18rem;
$inset-80: 20rem;
$inset-96: 24rem;
$inset-auto: auto;
$inset-px: 1px;
$inset-0-5: 0.125rem;
$inset-1-5: 0.375rem;
$inset-2-5: 0.625rem;
$inset-3-5: 0.875rem;
$inset-1\/2: 50%;
$inset-1\/3: 33.333333%;
$inset-2\/3: 66.666667%;
$inset-1\/4: 25%;
$inset-2\/4: 50%;
$inset-3\/4: 75%;
$inset-full: 100%;

$letterSpacing-tighter: -0.05em;
$letterSpacing-tight: -0.025em;
$letterSpacing-normal: 0em;
$letterSpacing-wide: 0.025em;
$letterSpacing-wider: 0.05em;
$letterSpacing-widest: 0.1em;

$lineHeight-3: .75rem;
$lineHeight-4: 1rem;
$lineHeight-5: 1.25rem;
$lineHeight-6: 1.5rem;
$lineHeight-7: 1.75rem;
$lineHeight-8: 2rem;
$lineHeight-9: 2.25rem;
$lineHeight-10: 2.5rem;
$lineHeight-none: 1;
$lineHeight-tight: 1.25;
$lineHeight-snug: 1.375;
$lineHeight-normal: 1.5;
$lineHeight-relaxed: 1.625;
$lineHeight-loose: 2;

$listStyleType-none: none;
$listStyleType-disc: disc;
$listStyleType-decimal: decimal;

$margin-0: 0px;
$margin-1: 0.25rem;
$margin-2: 0.5rem;
$margin-3: 0.75rem;
$margin-4: 1rem;
$margin-5: 1.25rem;
$margin-6: 1.5rem;
$margin-7: 1.75rem;
$margin-8: 2rem;
$margin-9: 2.25rem;
$margin-10: 2.5rem;
$margin-11: 2.75rem;
$margin-12: 3rem;
$margin-14: 3.5rem;
$margin-16: 4rem;
$margin-20: 5rem;
$margin-24: 6rem;
$margin-28: 7rem;
$margin-32: 8rem;
$margin-36: 9rem;
$margin-40: 10rem;
$margin-44: 11rem;
$margin-48: 12rem;
$margin-52: 13rem;
$margin-56: 14rem;
$margin-60: 15rem;
$margin-64: 16rem;
$margin-72: 18rem;
$margin-80: 20rem;
$margin-96: 24rem;
$margin-auto: auto;
$margin-px: 1px;
$margin-0-5: 0.125rem;
$margin-1-5: 0.375rem;
$margin-2-5: 0.625rem;
$margin-3-5: 0.875rem;

$maxHeight-0: 0px;
$maxHeight-1: 0.25rem;
$maxHeight-2: 0.5rem;
$maxHeight-3: 0.75rem;
$maxHeight-4: 1rem;
$maxHeight-5: 1.25rem;
$maxHeight-6: 1.5rem;
$maxHeight-7: 1.75rem;
$maxHeight-8: 2rem;
$maxHeight-9: 2.25rem;
$maxHeight-10: 2.5rem;
$maxHeight-11: 2.75rem;
$maxHeight-12: 3rem;
$maxHeight-14: 3.5rem;
$maxHeight-16: 4rem;
$maxHeight-20: 5rem;
$maxHeight-24: 6rem;
$maxHeight-28: 7rem;
$maxHeight-32: 8rem;
$maxHeight-36: 9rem;
$maxHeight-40: 10rem;
$maxHeight-44: 11rem;
$maxHeight-48: 12rem;
$maxHeight-52: 13rem;
$maxHeight-56: 14rem;
$maxHeight-60: 15rem;
$maxHeight-64: 16rem;
$maxHeight-72: 18rem;
$maxHeight-80: 20rem;
$maxHeight-96: 24rem;
$maxHeight-px: 1px;
$maxHeight-0-5: 0.125rem;
$maxHeight-1-5: 0.375rem;
$maxHeight-2-5: 0.625rem;
$maxHeight-3-5: 0.875rem;
$maxHeight-full: 100%;
$maxHeight-screen: 100vh;
$maxHeight-min: min-content;
$maxHeight-max: max-content;
$maxHeight-fit: fit-content;

$maxWidth-0: 0rem;
$maxWidth-none: none;
$maxWidth-xs: 20rem;
$maxWidth-sm: 24rem;
$maxWidth-md: 28rem;
$maxWidth-lg: 32rem;
$maxWidth-xl: 36rem;
$maxWidth-2xl: 42rem;
$maxWidth-3xl: 48rem;
$maxWidth-4xl: 56rem;
$maxWidth-5xl: 64rem;
$maxWidth-6xl: 72rem;
$maxWidth-7xl: 80rem;
$maxWidth-full: 100%;
$maxWidth-min: min-content;
$maxWidth-max: max-content;
$maxWidth-fit: fit-content;
$maxWidth-prose: 65ch;
$maxWidth-screen-sm: 640px;
$maxWidth-screen-md: 768px;
$maxWidth-screen-lg: 1024px;
$maxWidth-screen-xl: 1280px;
$maxWidth-screen-2xl: 1536px;

$minHeight-0: 0px;
$minHeight-full: 100%;
$minHeight-screen: 100vh;
$minHeight-min: min-content;
$minHeight-max: max-content;
$minHeight-fit: fit-content;

$minWidth-0: 0px;
$minWidth-full: 100%;
$minWidth-min: min-content;
$minWidth-max: max-content;
$minWidth-fit: fit-content;

$objectPosition-bottom: bottom;
$objectPosition-center: center;
$objectPosition-left: left;
$objectPosition-left-bottom: left bottom;
$objectPosition-left-top: left top;
$objectPosition-right: right;
$objectPosition-right-bottom: right bottom;
$objectPosition-right-top: right top;
$objectPosition-top: top;

$opacity-0: 0;
$opacity-5: 0.05;
$opacity-10: 0.1;
$opacity-20: 0.2;
$opacity-25: 0.25;
$opacity-30: 0.3;
$opacity-40: 0.4;
$opacity-50: 0.5;
$opacity-60: 0.6;
$opacity-70: 0.7;
$opacity-75: 0.75;
$opacity-80: 0.8;
$opacity-90: 0.9;
$opacity-95: 0.95;
$opacity-100: 1;

$order-1: 1;
$order-2: 2;
$order-3: 3;
$order-4: 4;
$order-5: 5;
$order-6: 6;
$order-7: 7;
$order-8: 8;
$order-9: 9;
$order-10: 10;
$order-11: 11;
$order-12: 12;
$order-first: -9999;
$order-last: 9999;
$order-none: 0;

$padding-0: 0px;
$padding-1: 0.25rem;
$padding-2: 0.5rem;
$padding-3: 0.75rem;
$padding-4: 1rem;
$padding-5: 1.25rem;
$padding-6: 1.5rem;
$padding-7: 1.75rem;
$padding-8: 2rem;
$padding-9: 2.25rem;
$padding-10: 2.5rem;
$padding-11: 2.75rem;
$padding-12: 3rem;
$padding-14: 3.5rem;
$padding-16: 4rem;
$padding-20: 5rem;
$padding-24: 6rem;
$padding-28: 7rem;
$padding-32: 8rem;
$padding-36: 9rem;
$padding-40: 10rem;
$padding-44: 11rem;
$padding-48: 12rem;
$padding-52: 13rem;
$padding-56: 14rem;
$padding-60: 15rem;
$padding-64: 16rem;
$padding-72: 18rem;
$padding-80: 20rem;
$padding-96: 24rem;
$padding-px: 1px;
$padding-0-5: 0.125rem;
$padding-1-5: 0.375rem;
$padding-2-5: 0.625rem;
$padding-3-5: 0.875rem;

$placeholderColor-transparent: transparent;
$placeholderColor-current: currentColor;
$placeholderColor-error-ultralight: #FEF2F2;
$placeholderColor-error-light: #FEE2E2;
$placeholderColor-error-medium: #C82929;
$placeholderColor-error-dark: #991B1B;
$placeholderColor-error-ultradark: #7F1D1D;
$placeholderColor-success-light: #DCFCE7;
$placeholderColor-success-medium: #15803D;
$placeholderColor-success-dark: #14532D;
$placeholderColor-warning-light: #FEF3C7;
$placeholderColor-warning-medium: #D97706;
$placeholderColor-warning-dark: #92400E;
$placeholderColor-black: #000;
$placeholderColor-white: #fff;
$placeholderColor-gray-50: #f9fafb;
$placeholderColor-gray-100: #f3f4f6;
$placeholderColor-gray-200: #e5e7eb;
$placeholderColor-gray-300: #d1d5db;
$placeholderColor-gray-400: #9ca3af;
$placeholderColor-gray-500: #6b7280;
$placeholderColor-gray-600: #4b5563;
$placeholderColor-gray-700: #374151;
$placeholderColor-gray-800: #1f2937;
$placeholderColor-gray-900: #111827;
$placeholderColor-zinc-50: #fafafa;
$placeholderColor-zinc-100: #f4f4f5;
$placeholderColor-zinc-200: #e4e4e7;
$placeholderColor-zinc-300: #d4d4d8;
$placeholderColor-zinc-400: #a1a1aa;
$placeholderColor-zinc-500: #71717a;
$placeholderColor-zinc-600: #52525b;
$placeholderColor-zinc-700: #3f3f46;
$placeholderColor-zinc-800: #27272a;
$placeholderColor-zinc-900: #18181b;
$placeholderColor-red-50: #fef2f2;
$placeholderColor-red-100: #fee2e2;
$placeholderColor-red-200: #fecaca;
$placeholderColor-red-300: #fca5a5;
$placeholderColor-red-400: #f87171;
$placeholderColor-red-500: #ef4444;
$placeholderColor-red-600: #dc2626;
$placeholderColor-red-700: #b91c1c;
$placeholderColor-red-800: #991b1b;
$placeholderColor-red-900: #7f1d1d;
$placeholderColor-yellow-50: #fffbeb;
$placeholderColor-yellow-100: #fef3c7;
$placeholderColor-yellow-200: #fde68a;
$placeholderColor-yellow-300: #fcd34d;
$placeholderColor-yellow-400: #fbbf24;
$placeholderColor-yellow-500: #f59e0b;
$placeholderColor-yellow-600: #d97706;
$placeholderColor-yellow-700: #b45309;
$placeholderColor-yellow-800: #92400e;
$placeholderColor-yellow-900: #78350f;
$placeholderColor-green-50: #f0fdf4;
$placeholderColor-green-100: #dcfce7;
$placeholderColor-green-200: #bbf7d0;
$placeholderColor-green-300: #86efac;
$placeholderColor-green-400: #4ade80;
$placeholderColor-green-500: #22c55e;
$placeholderColor-green-600: #16a34a;
$placeholderColor-green-700: #15803d;
$placeholderColor-green-800: #166534;
$placeholderColor-green-900: #14532d;
$placeholderColor-blue-100: #F0F5FF;
$placeholderColor-blue-200: #D6E4FF;
$placeholderColor-blue-300: #ADC6FF;
$placeholderColor-blue-400: #85A5FF;
$placeholderColor-blue-500: #597EF7;
$placeholderColor-blue-600: #2F54EB;
$placeholderColor-blue-700: #1D39C4;
$placeholderColor-blue-800: #10239E;
$placeholderColor-blue-900: #061178;
$placeholderColor-indigo-50: #eef2ff;
$placeholderColor-indigo-100: #e0e7ff;
$placeholderColor-indigo-200: #c7d2fe;
$placeholderColor-indigo-300: #a5b4fc;
$placeholderColor-indigo-400: #818cf8;
$placeholderColor-indigo-500: #6366f1;
$placeholderColor-indigo-600: #4f46e5;
$placeholderColor-indigo-700: #4338ca;
$placeholderColor-indigo-800: #3730a3;
$placeholderColor-indigo-900: #312e81;
$placeholderColor-purple-50: #f5f3ff;
$placeholderColor-purple-100: #ede9fe;
$placeholderColor-purple-200: #ddd6fe;
$placeholderColor-purple-300: #c4b5fd;
$placeholderColor-purple-400: #a78bfa;
$placeholderColor-purple-500: #8b5cf6;
$placeholderColor-purple-600: #7c3aed;
$placeholderColor-purple-700: #6d28d9;
$placeholderColor-purple-800: #5b21b6;
$placeholderColor-purple-900: #4c1d95;
$placeholderColor-pink-50: #fdf2f8;
$placeholderColor-pink-100: #fce7f3;
$placeholderColor-pink-200: #fbcfe8;
$placeholderColor-pink-300: #f9a8d4;
$placeholderColor-pink-400: #f472b6;
$placeholderColor-pink-500: #ec4899;
$placeholderColor-pink-600: #db2777;
$placeholderColor-pink-700: #be185d;
$placeholderColor-pink-800: #9d174d;
$placeholderColor-pink-900: #831843;
$placeholderColor-emerald-50: #ecfdf5;
$placeholderColor-emerald-100: #d1fae5;
$placeholderColor-emerald-200: #a7f3d0;
$placeholderColor-emerald-300: #6ee7b7;
$placeholderColor-emerald-400: #34d399;
$placeholderColor-emerald-500: #10b981;
$placeholderColor-emerald-600: #059669;
$placeholderColor-emerald-700: #047857;
$placeholderColor-emerald-800: #065f46;
$placeholderColor-emerald-900: #064e3b;

$placeholderOpacity-0: 0;
$placeholderOpacity-5: 0.05;
$placeholderOpacity-10: 0.1;
$placeholderOpacity-20: 0.2;
$placeholderOpacity-25: 0.25;
$placeholderOpacity-30: 0.3;
$placeholderOpacity-40: 0.4;
$placeholderOpacity-50: 0.5;
$placeholderOpacity-60: 0.6;
$placeholderOpacity-70: 0.7;
$placeholderOpacity-75: 0.75;
$placeholderOpacity-80: 0.8;
$placeholderOpacity-90: 0.9;
$placeholderOpacity-95: 0.95;
$placeholderOpacity-100: 1;

$outlineColor-transparent: transparent;
$outlineColor-current: currentColor;
$outlineColor-error-ultralight: #FEF2F2;
$outlineColor-error-light: #FEE2E2;
$outlineColor-error-medium: #C82929;
$outlineColor-error-dark: #991B1B;
$outlineColor-error-ultradark: #7F1D1D;
$outlineColor-success-light: #DCFCE7;
$outlineColor-success-medium: #15803D;
$outlineColor-success-dark: #14532D;
$outlineColor-warning-light: #FEF3C7;
$outlineColor-warning-medium: #D97706;
$outlineColor-warning-dark: #92400E;
$outlineColor-black: #000;
$outlineColor-white: #fff;
$outlineColor-gray-50: #f9fafb;
$outlineColor-gray-100: #f3f4f6;
$outlineColor-gray-200: #e5e7eb;
$outlineColor-gray-300: #d1d5db;
$outlineColor-gray-400: #9ca3af;
$outlineColor-gray-500: #6b7280;
$outlineColor-gray-600: #4b5563;
$outlineColor-gray-700: #374151;
$outlineColor-gray-800: #1f2937;
$outlineColor-gray-900: #111827;
$outlineColor-zinc-50: #fafafa;
$outlineColor-zinc-100: #f4f4f5;
$outlineColor-zinc-200: #e4e4e7;
$outlineColor-zinc-300: #d4d4d8;
$outlineColor-zinc-400: #a1a1aa;
$outlineColor-zinc-500: #71717a;
$outlineColor-zinc-600: #52525b;
$outlineColor-zinc-700: #3f3f46;
$outlineColor-zinc-800: #27272a;
$outlineColor-zinc-900: #18181b;
$outlineColor-red-50: #fef2f2;
$outlineColor-red-100: #fee2e2;
$outlineColor-red-200: #fecaca;
$outlineColor-red-300: #fca5a5;
$outlineColor-red-400: #f87171;
$outlineColor-red-500: #ef4444;
$outlineColor-red-600: #dc2626;
$outlineColor-red-700: #b91c1c;
$outlineColor-red-800: #991b1b;
$outlineColor-red-900: #7f1d1d;
$outlineColor-yellow-50: #fffbeb;
$outlineColor-yellow-100: #fef3c7;
$outlineColor-yellow-200: #fde68a;
$outlineColor-yellow-300: #fcd34d;
$outlineColor-yellow-400: #fbbf24;
$outlineColor-yellow-500: #f59e0b;
$outlineColor-yellow-600: #d97706;
$outlineColor-yellow-700: #b45309;
$outlineColor-yellow-800: #92400e;
$outlineColor-yellow-900: #78350f;
$outlineColor-green-50: #f0fdf4;
$outlineColor-green-100: #dcfce7;
$outlineColor-green-200: #bbf7d0;
$outlineColor-green-300: #86efac;
$outlineColor-green-400: #4ade80;
$outlineColor-green-500: #22c55e;
$outlineColor-green-600: #16a34a;
$outlineColor-green-700: #15803d;
$outlineColor-green-800: #166534;
$outlineColor-green-900: #14532d;
$outlineColor-blue-100: #F0F5FF;
$outlineColor-blue-200: #D6E4FF;
$outlineColor-blue-300: #ADC6FF;
$outlineColor-blue-400: #85A5FF;
$outlineColor-blue-500: #597EF7;
$outlineColor-blue-600: #2F54EB;
$outlineColor-blue-700: #1D39C4;
$outlineColor-blue-800: #10239E;
$outlineColor-blue-900: #061178;
$outlineColor-indigo-50: #eef2ff;
$outlineColor-indigo-100: #e0e7ff;
$outlineColor-indigo-200: #c7d2fe;
$outlineColor-indigo-300: #a5b4fc;
$outlineColor-indigo-400: #818cf8;
$outlineColor-indigo-500: #6366f1;
$outlineColor-indigo-600: #4f46e5;
$outlineColor-indigo-700: #4338ca;
$outlineColor-indigo-800: #3730a3;
$outlineColor-indigo-900: #312e81;
$outlineColor-purple-50: #f5f3ff;
$outlineColor-purple-100: #ede9fe;
$outlineColor-purple-200: #ddd6fe;
$outlineColor-purple-300: #c4b5fd;
$outlineColor-purple-400: #a78bfa;
$outlineColor-purple-500: #8b5cf6;
$outlineColor-purple-600: #7c3aed;
$outlineColor-purple-700: #6d28d9;
$outlineColor-purple-800: #5b21b6;
$outlineColor-purple-900: #4c1d95;
$outlineColor-pink-50: #fdf2f8;
$outlineColor-pink-100: #fce7f3;
$outlineColor-pink-200: #fbcfe8;
$outlineColor-pink-300: #f9a8d4;
$outlineColor-pink-400: #f472b6;
$outlineColor-pink-500: #ec4899;
$outlineColor-pink-600: #db2777;
$outlineColor-pink-700: #be185d;
$outlineColor-pink-800: #9d174d;
$outlineColor-pink-900: #831843;
$outlineColor-emerald-50: #ecfdf5;
$outlineColor-emerald-100: #d1fae5;
$outlineColor-emerald-200: #a7f3d0;
$outlineColor-emerald-300: #6ee7b7;
$outlineColor-emerald-400: #34d399;
$outlineColor-emerald-500: #10b981;
$outlineColor-emerald-600: #059669;
$outlineColor-emerald-700: #047857;
$outlineColor-emerald-800: #065f46;
$outlineColor-emerald-900: #064e3b;

$outlineOffset-0: 0px;
$outlineOffset-1: 1px;
$outlineOffset-2: 2px;
$outlineOffset-4: 4px;
$outlineOffset-8: 8px;

$outlineWidth-0: 0px;
$outlineWidth-1: 1px;
$outlineWidth-2: 2px;
$outlineWidth-4: 4px;
$outlineWidth-8: 8px;

$ringColor-DEFAULT: #597EF7;
$ringColor-transparent: transparent;
$ringColor-current: currentColor;
$ringColor-error-ultralight: #FEF2F2;
$ringColor-error-light: #FEE2E2;
$ringColor-error-medium: #C82929;
$ringColor-error-dark: #991B1B;
$ringColor-error-ultradark: #7F1D1D;
$ringColor-success-light: #DCFCE7;
$ringColor-success-medium: #15803D;
$ringColor-success-dark: #14532D;
$ringColor-warning-light: #FEF3C7;
$ringColor-warning-medium: #D97706;
$ringColor-warning-dark: #92400E;
$ringColor-black: #000;
$ringColor-white: #fff;
$ringColor-gray-50: #f9fafb;
$ringColor-gray-100: #f3f4f6;
$ringColor-gray-200: #e5e7eb;
$ringColor-gray-300: #d1d5db;
$ringColor-gray-400: #9ca3af;
$ringColor-gray-500: #6b7280;
$ringColor-gray-600: #4b5563;
$ringColor-gray-700: #374151;
$ringColor-gray-800: #1f2937;
$ringColor-gray-900: #111827;
$ringColor-zinc-50: #fafafa;
$ringColor-zinc-100: #f4f4f5;
$ringColor-zinc-200: #e4e4e7;
$ringColor-zinc-300: #d4d4d8;
$ringColor-zinc-400: #a1a1aa;
$ringColor-zinc-500: #71717a;
$ringColor-zinc-600: #52525b;
$ringColor-zinc-700: #3f3f46;
$ringColor-zinc-800: #27272a;
$ringColor-zinc-900: #18181b;
$ringColor-red-50: #fef2f2;
$ringColor-red-100: #fee2e2;
$ringColor-red-200: #fecaca;
$ringColor-red-300: #fca5a5;
$ringColor-red-400: #f87171;
$ringColor-red-500: #ef4444;
$ringColor-red-600: #dc2626;
$ringColor-red-700: #b91c1c;
$ringColor-red-800: #991b1b;
$ringColor-red-900: #7f1d1d;
$ringColor-yellow-50: #fffbeb;
$ringColor-yellow-100: #fef3c7;
$ringColor-yellow-200: #fde68a;
$ringColor-yellow-300: #fcd34d;
$ringColor-yellow-400: #fbbf24;
$ringColor-yellow-500: #f59e0b;
$ringColor-yellow-600: #d97706;
$ringColor-yellow-700: #b45309;
$ringColor-yellow-800: #92400e;
$ringColor-yellow-900: #78350f;
$ringColor-green-50: #f0fdf4;
$ringColor-green-100: #dcfce7;
$ringColor-green-200: #bbf7d0;
$ringColor-green-300: #86efac;
$ringColor-green-400: #4ade80;
$ringColor-green-500: #22c55e;
$ringColor-green-600: #16a34a;
$ringColor-green-700: #15803d;
$ringColor-green-800: #166534;
$ringColor-green-900: #14532d;
$ringColor-blue-100: #F0F5FF;
$ringColor-blue-200: #D6E4FF;
$ringColor-blue-300: #ADC6FF;
$ringColor-blue-400: #85A5FF;
$ringColor-blue-500: #597EF7;
$ringColor-blue-600: #2F54EB;
$ringColor-blue-700: #1D39C4;
$ringColor-blue-800: #10239E;
$ringColor-blue-900: #061178;
$ringColor-indigo-50: #eef2ff;
$ringColor-indigo-100: #e0e7ff;
$ringColor-indigo-200: #c7d2fe;
$ringColor-indigo-300: #a5b4fc;
$ringColor-indigo-400: #818cf8;
$ringColor-indigo-500: #6366f1;
$ringColor-indigo-600: #4f46e5;
$ringColor-indigo-700: #4338ca;
$ringColor-indigo-800: #3730a3;
$ringColor-indigo-900: #312e81;
$ringColor-purple-50: #f5f3ff;
$ringColor-purple-100: #ede9fe;
$ringColor-purple-200: #ddd6fe;
$ringColor-purple-300: #c4b5fd;
$ringColor-purple-400: #a78bfa;
$ringColor-purple-500: #8b5cf6;
$ringColor-purple-600: #7c3aed;
$ringColor-purple-700: #6d28d9;
$ringColor-purple-800: #5b21b6;
$ringColor-purple-900: #4c1d95;
$ringColor-pink-50: #fdf2f8;
$ringColor-pink-100: #fce7f3;
$ringColor-pink-200: #fbcfe8;
$ringColor-pink-300: #f9a8d4;
$ringColor-pink-400: #f472b6;
$ringColor-pink-500: #ec4899;
$ringColor-pink-600: #db2777;
$ringColor-pink-700: #be185d;
$ringColor-pink-800: #9d174d;
$ringColor-pink-900: #831843;
$ringColor-emerald-50: #ecfdf5;
$ringColor-emerald-100: #d1fae5;
$ringColor-emerald-200: #a7f3d0;
$ringColor-emerald-300: #6ee7b7;
$ringColor-emerald-400: #34d399;
$ringColor-emerald-500: #10b981;
$ringColor-emerald-600: #059669;
$ringColor-emerald-700: #047857;
$ringColor-emerald-800: #065f46;
$ringColor-emerald-900: #064e3b;

$ringOffsetColor-transparent: transparent;
$ringOffsetColor-current: currentColor;
$ringOffsetColor-error-ultralight: #FEF2F2;
$ringOffsetColor-error-light: #FEE2E2;
$ringOffsetColor-error-medium: #C82929;
$ringOffsetColor-error-dark: #991B1B;
$ringOffsetColor-error-ultradark: #7F1D1D;
$ringOffsetColor-success-light: #DCFCE7;
$ringOffsetColor-success-medium: #15803D;
$ringOffsetColor-success-dark: #14532D;
$ringOffsetColor-warning-light: #FEF3C7;
$ringOffsetColor-warning-medium: #D97706;
$ringOffsetColor-warning-dark: #92400E;
$ringOffsetColor-black: #000;
$ringOffsetColor-white: #fff;
$ringOffsetColor-gray-50: #f9fafb;
$ringOffsetColor-gray-100: #f3f4f6;
$ringOffsetColor-gray-200: #e5e7eb;
$ringOffsetColor-gray-300: #d1d5db;
$ringOffsetColor-gray-400: #9ca3af;
$ringOffsetColor-gray-500: #6b7280;
$ringOffsetColor-gray-600: #4b5563;
$ringOffsetColor-gray-700: #374151;
$ringOffsetColor-gray-800: #1f2937;
$ringOffsetColor-gray-900: #111827;
$ringOffsetColor-zinc-50: #fafafa;
$ringOffsetColor-zinc-100: #f4f4f5;
$ringOffsetColor-zinc-200: #e4e4e7;
$ringOffsetColor-zinc-300: #d4d4d8;
$ringOffsetColor-zinc-400: #a1a1aa;
$ringOffsetColor-zinc-500: #71717a;
$ringOffsetColor-zinc-600: #52525b;
$ringOffsetColor-zinc-700: #3f3f46;
$ringOffsetColor-zinc-800: #27272a;
$ringOffsetColor-zinc-900: #18181b;
$ringOffsetColor-red-50: #fef2f2;
$ringOffsetColor-red-100: #fee2e2;
$ringOffsetColor-red-200: #fecaca;
$ringOffsetColor-red-300: #fca5a5;
$ringOffsetColor-red-400: #f87171;
$ringOffsetColor-red-500: #ef4444;
$ringOffsetColor-red-600: #dc2626;
$ringOffsetColor-red-700: #b91c1c;
$ringOffsetColor-red-800: #991b1b;
$ringOffsetColor-red-900: #7f1d1d;
$ringOffsetColor-yellow-50: #fffbeb;
$ringOffsetColor-yellow-100: #fef3c7;
$ringOffsetColor-yellow-200: #fde68a;
$ringOffsetColor-yellow-300: #fcd34d;
$ringOffsetColor-yellow-400: #fbbf24;
$ringOffsetColor-yellow-500: #f59e0b;
$ringOffsetColor-yellow-600: #d97706;
$ringOffsetColor-yellow-700: #b45309;
$ringOffsetColor-yellow-800: #92400e;
$ringOffsetColor-yellow-900: #78350f;
$ringOffsetColor-green-50: #f0fdf4;
$ringOffsetColor-green-100: #dcfce7;
$ringOffsetColor-green-200: #bbf7d0;
$ringOffsetColor-green-300: #86efac;
$ringOffsetColor-green-400: #4ade80;
$ringOffsetColor-green-500: #22c55e;
$ringOffsetColor-green-600: #16a34a;
$ringOffsetColor-green-700: #15803d;
$ringOffsetColor-green-800: #166534;
$ringOffsetColor-green-900: #14532d;
$ringOffsetColor-blue-100: #F0F5FF;
$ringOffsetColor-blue-200: #D6E4FF;
$ringOffsetColor-blue-300: #ADC6FF;
$ringOffsetColor-blue-400: #85A5FF;
$ringOffsetColor-blue-500: #597EF7;
$ringOffsetColor-blue-600: #2F54EB;
$ringOffsetColor-blue-700: #1D39C4;
$ringOffsetColor-blue-800: #10239E;
$ringOffsetColor-blue-900: #061178;
$ringOffsetColor-indigo-50: #eef2ff;
$ringOffsetColor-indigo-100: #e0e7ff;
$ringOffsetColor-indigo-200: #c7d2fe;
$ringOffsetColor-indigo-300: #a5b4fc;
$ringOffsetColor-indigo-400: #818cf8;
$ringOffsetColor-indigo-500: #6366f1;
$ringOffsetColor-indigo-600: #4f46e5;
$ringOffsetColor-indigo-700: #4338ca;
$ringOffsetColor-indigo-800: #3730a3;
$ringOffsetColor-indigo-900: #312e81;
$ringOffsetColor-purple-50: #f5f3ff;
$ringOffsetColor-purple-100: #ede9fe;
$ringOffsetColor-purple-200: #ddd6fe;
$ringOffsetColor-purple-300: #c4b5fd;
$ringOffsetColor-purple-400: #a78bfa;
$ringOffsetColor-purple-500: #8b5cf6;
$ringOffsetColor-purple-600: #7c3aed;
$ringOffsetColor-purple-700: #6d28d9;
$ringOffsetColor-purple-800: #5b21b6;
$ringOffsetColor-purple-900: #4c1d95;
$ringOffsetColor-pink-50: #fdf2f8;
$ringOffsetColor-pink-100: #fce7f3;
$ringOffsetColor-pink-200: #fbcfe8;
$ringOffsetColor-pink-300: #f9a8d4;
$ringOffsetColor-pink-400: #f472b6;
$ringOffsetColor-pink-500: #ec4899;
$ringOffsetColor-pink-600: #db2777;
$ringOffsetColor-pink-700: #be185d;
$ringOffsetColor-pink-800: #9d174d;
$ringOffsetColor-pink-900: #831843;
$ringOffsetColor-emerald-50: #ecfdf5;
$ringOffsetColor-emerald-100: #d1fae5;
$ringOffsetColor-emerald-200: #a7f3d0;
$ringOffsetColor-emerald-300: #6ee7b7;
$ringOffsetColor-emerald-400: #34d399;
$ringOffsetColor-emerald-500: #10b981;
$ringOffsetColor-emerald-600: #059669;
$ringOffsetColor-emerald-700: #047857;
$ringOffsetColor-emerald-800: #065f46;
$ringOffsetColor-emerald-900: #064e3b;

$ringOffsetWidth-0: 0px;
$ringOffsetWidth-1: 1px;
$ringOffsetWidth-2: 2px;
$ringOffsetWidth-4: 4px;
$ringOffsetWidth-8: 8px;

$ringOpacity-0: 0;
$ringOpacity-5: 0.05;
$ringOpacity-10: 0.1;
$ringOpacity-20: 0.2;
$ringOpacity-25: 0.25;
$ringOpacity-30: 0.3;
$ringOpacity-40: 0.4;
$ringOpacity-50: 0.5;
$ringOpacity-60: 0.6;
$ringOpacity-70: 0.7;
$ringOpacity-75: 0.75;
$ringOpacity-80: 0.8;
$ringOpacity-90: 0.9;
$ringOpacity-95: 0.95;
$ringOpacity-100: 1;
$ringOpacity-DEFAULT: 0.5;

$ringWidth-0: 0px;
$ringWidth-1: 1px;
$ringWidth-2: 2px;
$ringWidth-4: 4px;
$ringWidth-8: 8px;
$ringWidth-DEFAULT: 3px;

$rotate-0: 0deg;
$rotate-1: 1deg;
$rotate-2: 2deg;
$rotate-3: 3deg;
$rotate-6: 6deg;
$rotate-12: 12deg;
$rotate-45: 45deg;
$rotate-90: 90deg;
$rotate-180: 180deg;

$saturate-0: 0;
$saturate-50: .5;
$saturate-100: 1;
$saturate-150: 1.5;
$saturate-200: 2;

$scale-0: 0;
$scale-50: .5;
$scale-75: .75;
$scale-90: .9;
$scale-95: .95;
$scale-100: 1;
$scale-105: 1.05;
$scale-110: 1.1;
$scale-125: 1.25;
$scale-150: 1.5;

$scrollMargin-0: 0px;
$scrollMargin-1: 0.25rem;
$scrollMargin-2: 0.5rem;
$scrollMargin-3: 0.75rem;
$scrollMargin-4: 1rem;
$scrollMargin-5: 1.25rem;
$scrollMargin-6: 1.5rem;
$scrollMargin-7: 1.75rem;
$scrollMargin-8: 2rem;
$scrollMargin-9: 2.25rem;
$scrollMargin-10: 2.5rem;
$scrollMargin-11: 2.75rem;
$scrollMargin-12: 3rem;
$scrollMargin-14: 3.5rem;
$scrollMargin-16: 4rem;
$scrollMargin-20: 5rem;
$scrollMargin-24: 6rem;
$scrollMargin-28: 7rem;
$scrollMargin-32: 8rem;
$scrollMargin-36: 9rem;
$scrollMargin-40: 10rem;
$scrollMargin-44: 11rem;
$scrollMargin-48: 12rem;
$scrollMargin-52: 13rem;
$scrollMargin-56: 14rem;
$scrollMargin-60: 15rem;
$scrollMargin-64: 16rem;
$scrollMargin-72: 18rem;
$scrollMargin-80: 20rem;
$scrollMargin-96: 24rem;
$scrollMargin-px: 1px;
$scrollMargin-0-5: 0.125rem;
$scrollMargin-1-5: 0.375rem;
$scrollMargin-2-5: 0.625rem;
$scrollMargin-3-5: 0.875rem;

$scrollPadding-0: 0px;
$scrollPadding-1: 0.25rem;
$scrollPadding-2: 0.5rem;
$scrollPadding-3: 0.75rem;
$scrollPadding-4: 1rem;
$scrollPadding-5: 1.25rem;
$scrollPadding-6: 1.5rem;
$scrollPadding-7: 1.75rem;
$scrollPadding-8: 2rem;
$scrollPadding-9: 2.25rem;
$scrollPadding-10: 2.5rem;
$scrollPadding-11: 2.75rem;
$scrollPadding-12: 3rem;
$scrollPadding-14: 3.5rem;
$scrollPadding-16: 4rem;
$scrollPadding-20: 5rem;
$scrollPadding-24: 6rem;
$scrollPadding-28: 7rem;
$scrollPadding-32: 8rem;
$scrollPadding-36: 9rem;
$scrollPadding-40: 10rem;
$scrollPadding-44: 11rem;
$scrollPadding-48: 12rem;
$scrollPadding-52: 13rem;
$scrollPadding-56: 14rem;
$scrollPadding-60: 15rem;
$scrollPadding-64: 16rem;
$scrollPadding-72: 18rem;
$scrollPadding-80: 20rem;
$scrollPadding-96: 24rem;
$scrollPadding-px: 1px;
$scrollPadding-0-5: 0.125rem;
$scrollPadding-1-5: 0.375rem;
$scrollPadding-2-5: 0.625rem;
$scrollPadding-3-5: 0.875rem;

$sepia-0: 0;
$sepia-DEFAULT: 100%;

$skew-0: 0deg;
$skew-1: 1deg;
$skew-2: 2deg;
$skew-3: 3deg;
$skew-6: 6deg;
$skew-12: 12deg;

$space-0: 0px;
$space-1: 0.25rem;
$space-2: 0.5rem;
$space-3: 0.75rem;
$space-4: 1rem;
$space-5: 1.25rem;
$space-6: 1.5rem;
$space-7: 1.75rem;
$space-8: 2rem;
$space-9: 2.25rem;
$space-10: 2.5rem;
$space-11: 2.75rem;
$space-12: 3rem;
$space-14: 3.5rem;
$space-16: 4rem;
$space-20: 5rem;
$space-24: 6rem;
$space-28: 7rem;
$space-32: 8rem;
$space-36: 9rem;
$space-40: 10rem;
$space-44: 11rem;
$space-48: 12rem;
$space-52: 13rem;
$space-56: 14rem;
$space-60: 15rem;
$space-64: 16rem;
$space-72: 18rem;
$space-80: 20rem;
$space-96: 24rem;
$space-px: 1px;
$space-0-5: 0.125rem;
$space-1-5: 0.375rem;
$space-2-5: 0.625rem;
$space-3-5: 0.875rem;

$stroke-transparent: transparent;
$stroke-current: currentColor;
$stroke-error-ultralight: #FEF2F2;
$stroke-error-light: #FEE2E2;
$stroke-error-medium: #C82929;
$stroke-error-dark: #991B1B;
$stroke-error-ultradark: #7F1D1D;
$stroke-success-light: #DCFCE7;
$stroke-success-medium: #15803D;
$stroke-success-dark: #14532D;
$stroke-warning-light: #FEF3C7;
$stroke-warning-medium: #D97706;
$stroke-warning-dark: #92400E;
$stroke-black: #000;
$stroke-white: #fff;
$stroke-gray-50: #f9fafb;
$stroke-gray-100: #f3f4f6;
$stroke-gray-200: #e5e7eb;
$stroke-gray-300: #d1d5db;
$stroke-gray-400: #9ca3af;
$stroke-gray-500: #6b7280;
$stroke-gray-600: #4b5563;
$stroke-gray-700: #374151;
$stroke-gray-800: #1f2937;
$stroke-gray-900: #111827;
$stroke-zinc-50: #fafafa;
$stroke-zinc-100: #f4f4f5;
$stroke-zinc-200: #e4e4e7;
$stroke-zinc-300: #d4d4d8;
$stroke-zinc-400: #a1a1aa;
$stroke-zinc-500: #71717a;
$stroke-zinc-600: #52525b;
$stroke-zinc-700: #3f3f46;
$stroke-zinc-800: #27272a;
$stroke-zinc-900: #18181b;
$stroke-red-50: #fef2f2;
$stroke-red-100: #fee2e2;
$stroke-red-200: #fecaca;
$stroke-red-300: #fca5a5;
$stroke-red-400: #f87171;
$stroke-red-500: #ef4444;
$stroke-red-600: #dc2626;
$stroke-red-700: #b91c1c;
$stroke-red-800: #991b1b;
$stroke-red-900: #7f1d1d;
$stroke-yellow-50: #fffbeb;
$stroke-yellow-100: #fef3c7;
$stroke-yellow-200: #fde68a;
$stroke-yellow-300: #fcd34d;
$stroke-yellow-400: #fbbf24;
$stroke-yellow-500: #f59e0b;
$stroke-yellow-600: #d97706;
$stroke-yellow-700: #b45309;
$stroke-yellow-800: #92400e;
$stroke-yellow-900: #78350f;
$stroke-green-50: #f0fdf4;
$stroke-green-100: #dcfce7;
$stroke-green-200: #bbf7d0;
$stroke-green-300: #86efac;
$stroke-green-400: #4ade80;
$stroke-green-500: #22c55e;
$stroke-green-600: #16a34a;
$stroke-green-700: #15803d;
$stroke-green-800: #166534;
$stroke-green-900: #14532d;
$stroke-blue-100: #F0F5FF;
$stroke-blue-200: #D6E4FF;
$stroke-blue-300: #ADC6FF;
$stroke-blue-400: #85A5FF;
$stroke-blue-500: #597EF7;
$stroke-blue-600: #2F54EB;
$stroke-blue-700: #1D39C4;
$stroke-blue-800: #10239E;
$stroke-blue-900: #061178;
$stroke-indigo-50: #eef2ff;
$stroke-indigo-100: #e0e7ff;
$stroke-indigo-200: #c7d2fe;
$stroke-indigo-300: #a5b4fc;
$stroke-indigo-400: #818cf8;
$stroke-indigo-500: #6366f1;
$stroke-indigo-600: #4f46e5;
$stroke-indigo-700: #4338ca;
$stroke-indigo-800: #3730a3;
$stroke-indigo-900: #312e81;
$stroke-purple-50: #f5f3ff;
$stroke-purple-100: #ede9fe;
$stroke-purple-200: #ddd6fe;
$stroke-purple-300: #c4b5fd;
$stroke-purple-400: #a78bfa;
$stroke-purple-500: #8b5cf6;
$stroke-purple-600: #7c3aed;
$stroke-purple-700: #6d28d9;
$stroke-purple-800: #5b21b6;
$stroke-purple-900: #4c1d95;
$stroke-pink-50: #fdf2f8;
$stroke-pink-100: #fce7f3;
$stroke-pink-200: #fbcfe8;
$stroke-pink-300: #f9a8d4;
$stroke-pink-400: #f472b6;
$stroke-pink-500: #ec4899;
$stroke-pink-600: #db2777;
$stroke-pink-700: #be185d;
$stroke-pink-800: #9d174d;
$stroke-pink-900: #831843;
$stroke-emerald-50: #ecfdf5;
$stroke-emerald-100: #d1fae5;
$stroke-emerald-200: #a7f3d0;
$stroke-emerald-300: #6ee7b7;
$stroke-emerald-400: #34d399;
$stroke-emerald-500: #10b981;
$stroke-emerald-600: #059669;
$stroke-emerald-700: #047857;
$stroke-emerald-800: #065f46;
$stroke-emerald-900: #064e3b;

$strokeWidth-0: 0;
$strokeWidth-1: 1;
$strokeWidth-2: 2;

$textColor-transparent: transparent;
$textColor-current: currentColor;
$textColor-error-ultralight: #FEF2F2;
$textColor-error-light: #FEE2E2;
$textColor-error-medium: #C82929;
$textColor-error-dark: #991B1B;
$textColor-error-ultradark: #7F1D1D;
$textColor-success-light: #DCFCE7;
$textColor-success-medium: #15803D;
$textColor-success-dark: #14532D;
$textColor-warning-light: #FEF3C7;
$textColor-warning-medium: #D97706;
$textColor-warning-dark: #92400E;
$textColor-black: #000;
$textColor-white: #fff;
$textColor-gray-50: #f9fafb;
$textColor-gray-100: #f3f4f6;
$textColor-gray-200: #e5e7eb;
$textColor-gray-300: #d1d5db;
$textColor-gray-400: #9ca3af;
$textColor-gray-500: #6b7280;
$textColor-gray-600: #4b5563;
$textColor-gray-700: #374151;
$textColor-gray-800: #1f2937;
$textColor-gray-900: #111827;
$textColor-zinc-50: #fafafa;
$textColor-zinc-100: #f4f4f5;
$textColor-zinc-200: #e4e4e7;
$textColor-zinc-300: #d4d4d8;
$textColor-zinc-400: #a1a1aa;
$textColor-zinc-500: #71717a;
$textColor-zinc-600: #52525b;
$textColor-zinc-700: #3f3f46;
$textColor-zinc-800: #27272a;
$textColor-zinc-900: #18181b;
$textColor-red-50: #fef2f2;
$textColor-red-100: #fee2e2;
$textColor-red-200: #fecaca;
$textColor-red-300: #fca5a5;
$textColor-red-400: #f87171;
$textColor-red-500: #ef4444;
$textColor-red-600: #dc2626;
$textColor-red-700: #b91c1c;
$textColor-red-800: #991b1b;
$textColor-red-900: #7f1d1d;
$textColor-yellow-50: #fffbeb;
$textColor-yellow-100: #fef3c7;
$textColor-yellow-200: #fde68a;
$textColor-yellow-300: #fcd34d;
$textColor-yellow-400: #fbbf24;
$textColor-yellow-500: #f59e0b;
$textColor-yellow-600: #d97706;
$textColor-yellow-700: #b45309;
$textColor-yellow-800: #92400e;
$textColor-yellow-900: #78350f;
$textColor-green-50: #f0fdf4;
$textColor-green-100: #dcfce7;
$textColor-green-200: #bbf7d0;
$textColor-green-300: #86efac;
$textColor-green-400: #4ade80;
$textColor-green-500: #22c55e;
$textColor-green-600: #16a34a;
$textColor-green-700: #15803d;
$textColor-green-800: #166534;
$textColor-green-900: #14532d;
$textColor-blue-100: #F0F5FF;
$textColor-blue-200: #D6E4FF;
$textColor-blue-300: #ADC6FF;
$textColor-blue-400: #85A5FF;
$textColor-blue-500: #597EF7;
$textColor-blue-600: #2F54EB;
$textColor-blue-700: #1D39C4;
$textColor-blue-800: #10239E;
$textColor-blue-900: #061178;
$textColor-indigo-50: #eef2ff;
$textColor-indigo-100: #e0e7ff;
$textColor-indigo-200: #c7d2fe;
$textColor-indigo-300: #a5b4fc;
$textColor-indigo-400: #818cf8;
$textColor-indigo-500: #6366f1;
$textColor-indigo-600: #4f46e5;
$textColor-indigo-700: #4338ca;
$textColor-indigo-800: #3730a3;
$textColor-indigo-900: #312e81;
$textColor-purple-50: #f5f3ff;
$textColor-purple-100: #ede9fe;
$textColor-purple-200: #ddd6fe;
$textColor-purple-300: #c4b5fd;
$textColor-purple-400: #a78bfa;
$textColor-purple-500: #8b5cf6;
$textColor-purple-600: #7c3aed;
$textColor-purple-700: #6d28d9;
$textColor-purple-800: #5b21b6;
$textColor-purple-900: #4c1d95;
$textColor-pink-50: #fdf2f8;
$textColor-pink-100: #fce7f3;
$textColor-pink-200: #fbcfe8;
$textColor-pink-300: #f9a8d4;
$textColor-pink-400: #f472b6;
$textColor-pink-500: #ec4899;
$textColor-pink-600: #db2777;
$textColor-pink-700: #be185d;
$textColor-pink-800: #9d174d;
$textColor-pink-900: #831843;
$textColor-emerald-50: #ecfdf5;
$textColor-emerald-100: #d1fae5;
$textColor-emerald-200: #a7f3d0;
$textColor-emerald-300: #6ee7b7;
$textColor-emerald-400: #34d399;
$textColor-emerald-500: #10b981;
$textColor-emerald-600: #059669;
$textColor-emerald-700: #047857;
$textColor-emerald-800: #065f46;
$textColor-emerald-900: #064e3b;

$textDecorationColor-transparent: transparent;
$textDecorationColor-current: currentColor;
$textDecorationColor-error-ultralight: #FEF2F2;
$textDecorationColor-error-light: #FEE2E2;
$textDecorationColor-error-medium: #C82929;
$textDecorationColor-error-dark: #991B1B;
$textDecorationColor-error-ultradark: #7F1D1D;
$textDecorationColor-success-light: #DCFCE7;
$textDecorationColor-success-medium: #15803D;
$textDecorationColor-success-dark: #14532D;
$textDecorationColor-warning-light: #FEF3C7;
$textDecorationColor-warning-medium: #D97706;
$textDecorationColor-warning-dark: #92400E;
$textDecorationColor-black: #000;
$textDecorationColor-white: #fff;
$textDecorationColor-gray-50: #f9fafb;
$textDecorationColor-gray-100: #f3f4f6;
$textDecorationColor-gray-200: #e5e7eb;
$textDecorationColor-gray-300: #d1d5db;
$textDecorationColor-gray-400: #9ca3af;
$textDecorationColor-gray-500: #6b7280;
$textDecorationColor-gray-600: #4b5563;
$textDecorationColor-gray-700: #374151;
$textDecorationColor-gray-800: #1f2937;
$textDecorationColor-gray-900: #111827;
$textDecorationColor-zinc-50: #fafafa;
$textDecorationColor-zinc-100: #f4f4f5;
$textDecorationColor-zinc-200: #e4e4e7;
$textDecorationColor-zinc-300: #d4d4d8;
$textDecorationColor-zinc-400: #a1a1aa;
$textDecorationColor-zinc-500: #71717a;
$textDecorationColor-zinc-600: #52525b;
$textDecorationColor-zinc-700: #3f3f46;
$textDecorationColor-zinc-800: #27272a;
$textDecorationColor-zinc-900: #18181b;
$textDecorationColor-red-50: #fef2f2;
$textDecorationColor-red-100: #fee2e2;
$textDecorationColor-red-200: #fecaca;
$textDecorationColor-red-300: #fca5a5;
$textDecorationColor-red-400: #f87171;
$textDecorationColor-red-500: #ef4444;
$textDecorationColor-red-600: #dc2626;
$textDecorationColor-red-700: #b91c1c;
$textDecorationColor-red-800: #991b1b;
$textDecorationColor-red-900: #7f1d1d;
$textDecorationColor-yellow-50: #fffbeb;
$textDecorationColor-yellow-100: #fef3c7;
$textDecorationColor-yellow-200: #fde68a;
$textDecorationColor-yellow-300: #fcd34d;
$textDecorationColor-yellow-400: #fbbf24;
$textDecorationColor-yellow-500: #f59e0b;
$textDecorationColor-yellow-600: #d97706;
$textDecorationColor-yellow-700: #b45309;
$textDecorationColor-yellow-800: #92400e;
$textDecorationColor-yellow-900: #78350f;
$textDecorationColor-green-50: #f0fdf4;
$textDecorationColor-green-100: #dcfce7;
$textDecorationColor-green-200: #bbf7d0;
$textDecorationColor-green-300: #86efac;
$textDecorationColor-green-400: #4ade80;
$textDecorationColor-green-500: #22c55e;
$textDecorationColor-green-600: #16a34a;
$textDecorationColor-green-700: #15803d;
$textDecorationColor-green-800: #166534;
$textDecorationColor-green-900: #14532d;
$textDecorationColor-blue-100: #F0F5FF;
$textDecorationColor-blue-200: #D6E4FF;
$textDecorationColor-blue-300: #ADC6FF;
$textDecorationColor-blue-400: #85A5FF;
$textDecorationColor-blue-500: #597EF7;
$textDecorationColor-blue-600: #2F54EB;
$textDecorationColor-blue-700: #1D39C4;
$textDecorationColor-blue-800: #10239E;
$textDecorationColor-blue-900: #061178;
$textDecorationColor-indigo-50: #eef2ff;
$textDecorationColor-indigo-100: #e0e7ff;
$textDecorationColor-indigo-200: #c7d2fe;
$textDecorationColor-indigo-300: #a5b4fc;
$textDecorationColor-indigo-400: #818cf8;
$textDecorationColor-indigo-500: #6366f1;
$textDecorationColor-indigo-600: #4f46e5;
$textDecorationColor-indigo-700: #4338ca;
$textDecorationColor-indigo-800: #3730a3;
$textDecorationColor-indigo-900: #312e81;
$textDecorationColor-purple-50: #f5f3ff;
$textDecorationColor-purple-100: #ede9fe;
$textDecorationColor-purple-200: #ddd6fe;
$textDecorationColor-purple-300: #c4b5fd;
$textDecorationColor-purple-400: #a78bfa;
$textDecorationColor-purple-500: #8b5cf6;
$textDecorationColor-purple-600: #7c3aed;
$textDecorationColor-purple-700: #6d28d9;
$textDecorationColor-purple-800: #5b21b6;
$textDecorationColor-purple-900: #4c1d95;
$textDecorationColor-pink-50: #fdf2f8;
$textDecorationColor-pink-100: #fce7f3;
$textDecorationColor-pink-200: #fbcfe8;
$textDecorationColor-pink-300: #f9a8d4;
$textDecorationColor-pink-400: #f472b6;
$textDecorationColor-pink-500: #ec4899;
$textDecorationColor-pink-600: #db2777;
$textDecorationColor-pink-700: #be185d;
$textDecorationColor-pink-800: #9d174d;
$textDecorationColor-pink-900: #831843;
$textDecorationColor-emerald-50: #ecfdf5;
$textDecorationColor-emerald-100: #d1fae5;
$textDecorationColor-emerald-200: #a7f3d0;
$textDecorationColor-emerald-300: #6ee7b7;
$textDecorationColor-emerald-400: #34d399;
$textDecorationColor-emerald-500: #10b981;
$textDecorationColor-emerald-600: #059669;
$textDecorationColor-emerald-700: #047857;
$textDecorationColor-emerald-800: #065f46;
$textDecorationColor-emerald-900: #064e3b;

$textDecorationThickness-0: 0px;
$textDecorationThickness-1: 1px;
$textDecorationThickness-2: 2px;
$textDecorationThickness-4: 4px;
$textDecorationThickness-8: 8px;
$textDecorationThickness-auto: auto;
$textDecorationThickness-from-font: from-font;

$textUnderlineOffset-0: 0px;
$textUnderlineOffset-1: 1px;
$textUnderlineOffset-2: 2px;
$textUnderlineOffset-4: 4px;
$textUnderlineOffset-8: 8px;
$textUnderlineOffset-auto: auto;

$textIndent-0: 0px;
$textIndent-1: 0.25rem;
$textIndent-2: 0.5rem;
$textIndent-3: 0.75rem;
$textIndent-4: 1rem;
$textIndent-5: 1.25rem;
$textIndent-6: 1.5rem;
$textIndent-7: 1.75rem;
$textIndent-8: 2rem;
$textIndent-9: 2.25rem;
$textIndent-10: 2.5rem;
$textIndent-11: 2.75rem;
$textIndent-12: 3rem;
$textIndent-14: 3.5rem;
$textIndent-16: 4rem;
$textIndent-20: 5rem;
$textIndent-24: 6rem;
$textIndent-28: 7rem;
$textIndent-32: 8rem;
$textIndent-36: 9rem;
$textIndent-40: 10rem;
$textIndent-44: 11rem;
$textIndent-48: 12rem;
$textIndent-52: 13rem;
$textIndent-56: 14rem;
$textIndent-60: 15rem;
$textIndent-64: 16rem;
$textIndent-72: 18rem;
$textIndent-80: 20rem;
$textIndent-96: 24rem;
$textIndent-px: 1px;
$textIndent-0-5: 0.125rem;
$textIndent-1-5: 0.375rem;
$textIndent-2-5: 0.625rem;
$textIndent-3-5: 0.875rem;

$textOpacity-0: 0;
$textOpacity-5: 0.05;
$textOpacity-10: 0.1;
$textOpacity-20: 0.2;
$textOpacity-25: 0.25;
$textOpacity-30: 0.3;
$textOpacity-40: 0.4;
$textOpacity-50: 0.5;
$textOpacity-60: 0.6;
$textOpacity-70: 0.7;
$textOpacity-75: 0.75;
$textOpacity-80: 0.8;
$textOpacity-90: 0.9;
$textOpacity-95: 0.95;
$textOpacity-100: 1;

$transformOrigin-center: center;
$transformOrigin-top: top;
$transformOrigin-top-right: top right;
$transformOrigin-right: right;
$transformOrigin-bottom-right: bottom right;
$transformOrigin-bottom: bottom;
$transformOrigin-bottom-left: bottom left;
$transformOrigin-left: left;
$transformOrigin-top-left: top left;

$transitionDelay-75: 75ms;
$transitionDelay-100: 100ms;
$transitionDelay-150: 150ms;
$transitionDelay-200: 200ms;
$transitionDelay-300: 300ms;
$transitionDelay-500: 500ms;
$transitionDelay-700: 700ms;
$transitionDelay-1000: 1000ms;

$transitionDuration-75: 75ms;
$transitionDuration-100: 100ms;
$transitionDuration-150: 150ms;
$transitionDuration-200: 200ms;
$transitionDuration-300: 300ms;
$transitionDuration-500: 500ms;
$transitionDuration-700: 700ms;
$transitionDuration-1000: 1000ms;
$transitionDuration-DEFAULT: 150ms;

$transitionProperty-none: none;
$transitionProperty-all: all;
$transitionProperty-DEFAULT: (color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter);
$transitionProperty-colors: (color, background-color, border-color, text-decoration-color, fill, stroke);
$transitionProperty-opacity: opacity;
$transitionProperty-shadow: box-shadow;
$transitionProperty-transform: transform;

$transitionTimingFunction-DEFAULT: (cubic-bezier(0.4, 0, 0.2, 1));
$transitionTimingFunction-linear: linear;
$transitionTimingFunction-in: (cubic-bezier(0.4, 0, 1, 1));
$transitionTimingFunction-out: (cubic-bezier(0, 0, 0.2, 1));
$transitionTimingFunction-in-out: (cubic-bezier(0.4, 0, 0.2, 1));

$translate-0: 0px;
$translate-1: 0.25rem;
$translate-2: 0.5rem;
$translate-3: 0.75rem;
$translate-4: 1rem;
$translate-5: 1.25rem;
$translate-6: 1.5rem;
$translate-7: 1.75rem;
$translate-8: 2rem;
$translate-9: 2.25rem;
$translate-10: 2.5rem;
$translate-11: 2.75rem;
$translate-12: 3rem;
$translate-14: 3.5rem;
$translate-16: 4rem;
$translate-20: 5rem;
$translate-24: 6rem;
$translate-28: 7rem;
$translate-32: 8rem;
$translate-36: 9rem;
$translate-40: 10rem;
$translate-44: 11rem;
$translate-48: 12rem;
$translate-52: 13rem;
$translate-56: 14rem;
$translate-60: 15rem;
$translate-64: 16rem;
$translate-72: 18rem;
$translate-80: 20rem;
$translate-96: 24rem;
$translate-px: 1px;
$translate-0-5: 0.125rem;
$translate-1-5: 0.375rem;
$translate-2-5: 0.625rem;
$translate-3-5: 0.875rem;
$translate-1\/2: 50%;
$translate-1\/3: 33.333333%;
$translate-2\/3: 66.666667%;
$translate-1\/4: 25%;
$translate-2\/4: 50%;
$translate-3\/4: 75%;
$translate-full: 100%;

$width-0: 0px;
$width-1: 0.25rem;
$width-2: 0.5rem;
$width-3: 0.75rem;
$width-4: 1rem;
$width-5: 1.25rem;
$width-6: 1.5rem;
$width-7: 1.75rem;
$width-8: 2rem;
$width-9: 2.25rem;
$width-10: 2.5rem;
$width-11: 2.75rem;
$width-12: 3rem;
$width-14: 3.5rem;
$width-16: 4rem;
$width-20: 5rem;
$width-24: 6rem;
$width-28: 7rem;
$width-32: 8rem;
$width-36: 9rem;
$width-40: 10rem;
$width-44: 11rem;
$width-48: 12rem;
$width-52: 13rem;
$width-56: 14rem;
$width-60: 15rem;
$width-64: 16rem;
$width-72: 18rem;
$width-80: 20rem;
$width-96: 24rem;
$width-auto: auto;
$width-px: 1px;
$width-0-5: 0.125rem;
$width-1-5: 0.375rem;
$width-2-5: 0.625rem;
$width-3-5: 0.875rem;
$width-1\/2: 50%;
$width-1\/3: 33.333333%;
$width-2\/3: 66.666667%;
$width-1\/4: 25%;
$width-2\/4: 50%;
$width-3\/4: 75%;
$width-1\/5: 20%;
$width-2\/5: 40%;
$width-3\/5: 60%;
$width-4\/5: 80%;
$width-1\/6: 16.666667%;
$width-2\/6: 33.333333%;
$width-3\/6: 50%;
$width-4\/6: 66.666667%;
$width-5\/6: 83.333333%;
$width-1\/12: 8.333333%;
$width-2\/12: 16.666667%;
$width-3\/12: 25%;
$width-4\/12: 33.333333%;
$width-5\/12: 41.666667%;
$width-6\/12: 50%;
$width-7\/12: 58.333333%;
$width-8\/12: 66.666667%;
$width-9\/12: 75%;
$width-10\/12: 83.333333%;
$width-11\/12: 91.666667%;
$width-full: 100%;
$width-screen: 100vw;
$width-min: min-content;
$width-max: max-content;
$width-fit: fit-content;

$willChange-auto: auto;
$willChange-scroll: scroll-position;
$willChange-contents: contents;
$willChange-transform: transform;

$zIndex-0: 0;
$zIndex-10: 10;
$zIndex-20: 20;
$zIndex-30: 30;
$zIndex-40: 40;
$zIndex-50: 50;
$zIndex-auto: auto;
