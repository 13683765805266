// Variables

$font-family: Inter, sans-serif;

$z-index-spinner: 1000;

$field-shadow-focus-default: 0px 0px 4px rgba(47, 84, 235, 1);

$header-height: 64px;
$footer-height: 64px;
$sidebar-width: 240px;
$z-index-header: 30;
$z-index-sider: 28;
$z-index-footer: 25;
$content-md-width: 80%;
