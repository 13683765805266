@import 'tailwind-variables';
@import 'variables';

.extd-tab {
	position: relative;
	display: inline-block;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-top: 1px solid $borderColor-zinc-300;
	border-left: 1px solid $borderColor-zinc-300;
	border-right: 1px solid $borderColor-zinc-300;
	margin-right: 8px;
	background: $backgroundColor-white;
	cursor: default;
	.handle {
		position: absolute;
		top: 0px;
		right: 0px;
		svg {
			color: $borderColor-gray-300; } }
	.handle-on {
		cursor: move; } }
