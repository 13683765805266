@import 'tailwind-variables';
@import 'typography';

.extd-dragger-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	gap: 4px;
	> span {
		width: 100%; } }
